import Vue from "vue";

const initialState = () => ({
});

const cookies = {
  state: initialState,
  actions: {
  },
  mutations: {
    setCookie(state, { key, value }) {
      Vue.set(state, key, value);
    },
  },
};

export default cookies;
