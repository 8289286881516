<template src="./template.html" />
<style scoped src="./style.css"></style>

<script>
import { signInByGoogle, sendResetEmail } from "@/apis/auth";
import { getImageURL } from "@/apis/firebase";
import { action } from "@/store/actions";
import Icon from "@/components/Icon/index.vue";
import { mapGetters } from "vuex";
import { preload } from "@/scripts/preload";

export default {
  name: "LoginPage",
  components: { Icon },
  data: () => ({
    backgroundREF: "gs://spellingame.appspot.com/public/BG/scrabble.jpg",
    backgroundURL: "https://firebasestorage.googleapis.com/v0/b/spellingame.appspot.com/o/public%2FBG%2Fscrabble.jpg?alt=media",
    logo: require("@/assets/logo-full.png"),
    googleIcon: require("@/assets/Google.svg"),
    account: null,
    password: null,
    password2: null,
    signin: false,
    forgotPassword: false,
  }),
  methods: {
    async loginByGoogle() {
      try {
        await signInByGoogle();
      } catch (error) {
        console.log(error);
      }
    },
    submit() {
      if (this.signin && (this.password !== this.password2)) {
        const notification = {
          icon: "exclamation",
          color: "yellow",
          title: "請重新輸入密碼",
          subtitle: "您輸入的密碼前後不一致，請再試一次",
        };
        this.$store.dispatch(action.setSimpleNotification, notification);
        this.password = null; this.password2 = null;
        return;
      }
      this.$store.dispatch("authByEmail", {
        email: this.account,
        password: this.password,
        signUp: this.signin,
      });
    },
    async sendPasswordResetEmail() {
      try {
        await sendResetEmail(this.account);
        this.$store.dispatch(action.setSimpleNotification, {
          icon: "exclamation",
          color: "blue",
          title: "密碼重設郵件已發送",
          subtitle: `請至您的信箱 (${this.account}) 收取，並依指示變更您的密碼`,
        });
      } catch (error) {
        if (error.code === "auth/invalid-email") {
          this.$store.dispatch(action.setSimpleNotification, {
            icon: "exclamation",
            color: "yellow",
            title: "無效的電子郵件信箱",
            subtitle: "",
          });
        }
      }
      this.forgotPassword = false;
    },
  },
  computed: {
    ...mapGetters(["isUserAuth"]),
    script() {
      const script = {
        forget: "忘記密碼？",
        loginWith: "使用 Google 帳號登入",
        signInByGoogle: "直接以 Google 帳號登入",
        newUser: "還沒有帳號嗎？",
        goSignin: "立即註冊",
        haveAccount: "已有帳號了嗎？",
        goLogin: "馬上登入",
        or: "或",
        resetPassword: "發送密碼重置電子郵件",
        cancel: "取消",
      };
      if (this.signin) { script.submit = "註冊"; } else { script.submit = "登入"; }
      return script;
    },
  },
  watch: {
    isUserAuth() {
      if (this.isUserAuth) {
        const path = { name: "Home" };
        if (this.$route.query.goto === "game" && this.$route.query.game) {
          path.name = "Menu"; path.query = { id: this.$route.query.game };
        }
        this.$router.replace(path);
      }
    },
    backgroundREF: {
      handler() {
        if (!this.backgroundREF || this.backgroundURL) { return; }
        getImageURL(this.backgroundREF).then((result) => {
          if (result.error) { console.log(result.error); } else {
            this.backgroundURL = result.data;
          }
        });
      },
      immediate: true,
    },
  },
  created() {
    // set background
    document.getElementById("backdrop").style.backgroundImage = `url('${this.backgroundURL}')`;

    this.account = this.$route.query.email;
  },
  mounted() {
    preload("https://firebasestorage.googleapis.com/v0/b/spellingame.appspot.com/o/public%2FBG%2Fbox.png?alt=media", "image", "head");
  },
};
</script>
