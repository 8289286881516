import api from "./cloudFunctions";

const ACCENT_MAP = new Map([
  ["Britain", "UK"],
  ["North America", "US"],
  ["UK", "UK"],
  ["US", "US"],
]);

export default async function synthesizeSpeech(userId, idToken, quizId, data, params = {}) {
  api.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  await api.patch(
    `user/${userId}/quiz/${quizId}`,
    { tts: data }, // DATA: {word: "", example: {word:"" , sentence:""}}
    {
      params: {
        gender: params.gender || "FEMALE",
        accent: ACCENT_MAP.get(params.accent) || "US",
        rate: params.rate || 0.8,
      },
    },
  );
}
