import Vue from "vue";
import { getDoc, onSnapshot } from "firebase/firestore";
import { getImageURL, setData } from "@/apis/firebase";

let lastUpdate;

const initialState = () => ({
  unsubscribes: new Map(),
});

const missions = {
  state: initialState,
  actions: {
    async getMission({ state, dispatch }, { reference } = {}) {
      if (!reference || reference.id in state) { return; }
      const snap = await getDoc(reference);
      if (!snap.exists()) { return; }
      // mount Mission
      dispatch("mountMission", { data: snap.data(), reference });
    },
    syncMission({ state, dispatch, commit }, { reference }) {
      if (!reference || state.unsubscribes.has(reference.id)) { return; }
      const unsubscribe = onSnapshot(reference, (snap) => {
        const now = Date.now();
        // ignore change while game just updated
        if (lastUpdate && (now - lastUpdate < 5000)) { return; }
        if (!snap.exists()) { return; }
        dispatch("mountMission", { data: snap.data(), reference });
      });
      commit("addUnsubscribe_Mission", { key: reference.id, unsubscribe });
    },
    async mountMission({ commit }, { data, reference }) {
      const mission = data;
      if (mission.imgURI) {
        const imgURL = await getImageURL(mission.imgURI);
        if (imgURL.error) { console.log(imgURL.error); } else {
          mission.imgURL = imgURL.data;
          delete mission.imgURI;
        }
      }
      mission.ref = reference;
      commit("addMission", {
        id: reference.id, mission,
      });
    },
    updateQuestionsList({ commit }, payload) {
      commit("updateQuestions", payload);
      setData({ questions: payload.questions }, payload.ref);
    },
    updateMissionConfig({ state }, payload) {
      if (!state[payload.ref.id]) { return; }
      const updateData = { config: JSON.parse(JSON.stringify(state[payload.ref.id].config || {})) };
      setData(updateData, payload.ref);
    },
  },
  mutations: {
    addMission(state, payload) {
      if (state[payload.id]) {
        state[payload.id] = payload.mission;
      } else {
        Vue.set(state, payload.id, payload.mission);
      }
    },
    addUnsubscribe_Mission(state, { key, unsubscribe }) {
      state.unsubscribes.set(key, unsubscribe);
    },
    updateQuestions(state, payload) {
      if (state[payload.id]) {
        state[payload.id].questions = payload.questions;
      }
    },
    resetMissions(state) {
      // Release firestore subscribe
      state.unsubscribes.forEach((unsubscribe) => unsubscribe());
      // Reset
      const newState = initialState();
      Object.keys(state).forEach((key) => {
        if (Object.keys(newState).includes(key)) {
          state[key] = newState[key];
        } else {
          delete state[key];
        }
      });
    },
    updateMission(state, payload) {
      if (state[payload.ref.id]) {
        state[payload.ref.id] = { ...state[payload.ref.id], ...payload.update };
        lastUpdate = Date.now();
      }
    },
  },
};

export default missions;
