import {
  doc, collection, onSnapshot, addDoc,
  query, getDocs, orderBy, limit, startAfter,
} from "firebase/firestore";
import { updateFlowStatus } from "@/apis/firebase";

let lastUpdate;
let hasReachedEnd = false;

const initialState = () => ({
  loaded: [],
  sorting: [],
  picking: [],
  dictation: [],
  memorizing: [],
  memorized: [],
  config: {
    loaded: { min: 0 },
    sorting: { min: 7 },
    picking: { min: 10 },
    dictation: { min: 10, max: 20 },
  },
  words: {},
  onSync: false,
  lastSnapshot: null,
  snapshots: [],
  version: 1,
});

async function upgradeFlowVersion(flow) {
  const updatedFlow = { ...flow };
  const tasks = Object.entries(flow.words).map(async ([wordId, word]) => {
    if (word.ref) { return; }
    const uploadData = {
      // firestore word data
      word: word.name,
      question: word.question,
      log: word.log,
    };
    if (word.phase) { uploadData.phase = word.phase; }
    if (word.grade) { uploadData.grade = word.grade; }
    const wordRef = await addDoc(collection(word.player, "words"), uploadData);
    updatedFlow.words[wordId] = {
      // flow.words word data
      id: word.id,
      name: word.name,
      question: word.question,
      player: word.player,
      ref: wordRef,
    };
    if (word.nextTime) { updatedFlow.words[wordId].nextTime = word.nextTime; }
    if (word.phase) { updatedFlow.words[wordId].phase = word.phase; }
    if (word.grade) { updatedFlow.words[wordId].grade = word.grade; }
    if (word.pregrade) { updatedFlow.words[wordId].pregrade = word.pregrade; }
    if (word.position) { updatedFlow.words[wordId].position = word.position; }
  });
  await Promise.all(tasks);
  updatedFlow.version = 1;
  return updatedFlow;
}

const status = {
  state: initialState,
  getters: {
    isPlayableByTypping(state) {
      if (state.dictation.length > state.config.dictation.min) { return true; }
      const now = Date.now();
      if (state.memorizing.some((word) => (word.nextTime <= now))) { return true; }
      return false;
    },
    isPlayableByDragging(state) {
      const minLoaded = state.config.loaded ? state.config.loaded.min : 0;
      return (state.loaded.length > minLoaded
        && state.dictation.length <= state.config.dictation.max
      );
    },
    existedWords(state) {
      const words = { id: new Set(), name: new Set() };
      const add = (word) => { words.id.add(word.id); words.name.add(word.name); };
      state.loaded.forEach(add);
      Object.values(state.words).forEach(add);
      return words;
    },
  },
  actions: {
    syncFlow({
      state, rootState, dispatch, commit,
    }) {
      if (state.onSync) { return null; }
      const reference = doc(rootState.user.ref, "statuses", "current");
      return new Promise((resolve, reject) => {
        let resolveOnce = () => {
          resolveOnce = () => {};
          resolve();
          commit("syncFlow", true);
        };
        const unsubscribe = onSnapshot(reference, async (snap) => {
          resolveOnce();
          if (!snap.exists()) { // create status
            dispatch("saveFlow", { initialize: true }); return;
          }
          if ((Date.now() - lastUpdate) < 4000) { return; }
          let flow = snap.data();
          Object.entries(flow.words).forEach(([wordId, word]) => {
            if (word.nextTime) { flow.words[wordId].nextTime = word.nextTime.toDate(); }
          });
          if (!flow.version) { // need to upgrade flow version
            flow = await upgradeFlowVersion(flow);
            dispatch("saveFlow", { data: flow });
          }
          commit("load", flow);
        }, reject);
        commit("addUnsubscribe", () => { unsubscribe(); commit("syncFlow", false); });
      });
    },
    saveFlow({ state, rootState }, {
      data = null,
      snapshot = false,
      initialize = false,
      restore = false,
    } = {}) {
      if (!initialize && !state.onSync) { return; } // prevent override blank data
      const flow = data ? { ...data } : { ...state };
      delete flow.onSync; delete flow.snapshots;
      updateFlowStatus(flow, rootState.user, { snapshot, initialize });
      if (restore) { return; } // reflash restore data
      lastUpdate = Date.now();
    },
    backupFlow({ state, rootState, dispatch }, data) {
      const flow = data ? { ...data } : { ...state };
      delete flow.onSync; delete flow.snapshots;
      updateFlowStatus(flow, rootState.user, { snapshot: true });
      flow.lastSnapshot = new Date();
      dispatch("saveFlow", { data: flow });
    },
    async getSnapshots({ state, rootState, commit }) {
      if (hasReachedEnd) { return; }
      let snapshots;
      if (state.snapshots.length === 0) {
        snapshots = await getDocs(query(
          collection(rootState.user.ref, "statuses"),
          orderBy("timestamp", "desc"),
          limit(5),
        ));
      } else {
        snapshots = await getDocs(query(
          collection(rootState.user.ref, "statuses"),
          orderBy("timestamp", "desc"),
          limit(5),
          startAfter(state.snapshots[state.snapshots.length - 1].timestamp),
        ));
      }
      if (snapshots.empty) { hasReachedEnd = true; return; }
      commit(
        "addSnapshots",
        // snapshots.docs.map((snap) => ({ id: snap.id, ...snap.data() })),
        snapshots.docs.map((snap) => (snap.data())),
      );
    },
  },
  mutations: {
    load(state, loaded) {
      Object.keys(state).forEach((param) => {
        state[param] = loaded[param] || state[param];
        if (param === "version" && !loaded[param]) {
          state[param] = 0;
        }
      });
    },
    syncFlow(state, value) { state.onSync = value; },
    resetFlow(state) {
      // Reset
      const newState = initialState();
      Object.keys(state).forEach((key) => {
        if (Object.keys(newState).includes(key)) {
          state[key] = newState[key];
        } else {
          delete state[key];
        }
      });
    },
    addSnapshots(state, snapshots) {
      state.snapshots.push(...snapshots);
    },
  },
};

export default status;
