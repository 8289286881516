import firebase from "@/plugins/firebase";
import {
  getFirestore, collection, addDoc, updateDoc, serverTimestamp,
  query, where, orderBy, limit, onSnapshot, getDocs, startAfter,
} from "firebase/firestore";
import { getFileURL, saveFile } from "../storage";
import { getUid } from "../auth";

const db = getFirestore(firebase);
const PACKETS = collection(db, "packets");

export function decoratePacket(packet, callback) {
  const data = packet;
  async function replaceURI(uri, onSuccess) {
    if (!uri) { return; }
    const url = await getFileURL(uri);
    if (!url) { return; }
    onSuccess(url);
  }
  const tasks = [];
  if (data.imgURI) {
    tasks.push(replaceURI(
      data.imgURI,
      (url) => { data.imgURL = url.data; delete data.imgURI; },
    ));
  }
  Promise.all(tasks).then(() => { callback(data); });
}

export async function updatePacket(updateData, DocRef) {
  const data = updateData;
  data.lastUpdate = serverTimestamp();
  await updateDoc(DocRef, data);
}

export async function createPacket(updateData) {
  const data = updateData;
  data.owner = getUid();
  data.exist = true;
  data.create = serverTimestamp();
  data.lastUpdate = serverTimestamp();
  const newPacket = await addDoc(PACKETS, data);
  return newPacket.id;
}

export function queryAllPackets(callback, { tag, lastVisible, orderByName } = {}) {
  const uid = getUid();
  const filter = [];
  if (tag) { filter.push(where("tags", "array-contains", tag)); }
  let q = query(
    PACKETS,
    where("owner", "==", uid),
    where("exist", "==", true),
    ...filter,
    limit(10),
  );
  if (orderByName) {
    q = query(q, orderBy("name", "asc"));
  } else {
    q = query(q, orderBy("lastUpdate", "desc"));
  }
  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(snapshot.docs);
  });
  return unsubscribe;
}

export async function uploadFile(file, name, packetId) {
  const uid = getUid();
  const fileName = `${name}.${file.type.split("/")[1]}`; // rename
  // file type
  let filetype = "";
  if (file.type.match("image.*")) {
    filetype = "image";
  } else if (file.type.match("audio.*")) {
    filetype = "audio";
  }
  const filePath = `${filetype}s/${uid}/packet/${packetId}/${fileName}`;
  // Upload the image to Cloud Storage.
  const result = await saveFile(filePath, file);
  return result;
}

// Query Packets
export async function queryPackets(
  {
    self, tag, word, max = 10, desc = true, lastVisible = null,
  } = {},
  sortBy = "lastUpdate",
) {
  const uid = getUid();
  const filter = [];
  if (self) { filter.push(where("owner", "==", uid)); }
  if (!self) { filter.push(where("public", "==", true)); }
  if (tag) {
    filter.push(where("tags", "array-contains", tag));
  } else if (word) {
    filter.push(where("wordsList", "array-contains", word));
  }
  let q = query(
    PACKETS,
    where("exist", "==", true),
    where("completed", "==", true),
    ...filter,
    orderBy(sortBy, desc ? "desc" : "asc"),
    limit(max),
  );
  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs;
}
