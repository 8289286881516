<script>
import Star from "@/components/RanksIcon/Star.vue";
import { action } from "@/store/actions";
import soundEffects from "@AUDIO/soundEffects";

function delay(sec) {
  return new Promise((resolve) => {
    setTimeout(() => { resolve(); }, sec * 1000);
  });
}
function gradient(from, to, sec, callback, { limit = undefined } = {}) {
  if (!Number.isInteger(from) || !Number.isInteger(to) || from === to) { return; }
  const steps = [];
  if (to > from) { for (let i = from + 1; i <= to; i++) { steps.push(i); } }
  if (from > to) {
    let final = to;
    if (from - to > limit && limit) { final = from - limit; }
    for (let i = from - 1; i >= final; i--) { steps.push(i); }
  }
  const span = sec / steps.length;
  steps.forEach((step, index) => { setTimeout(() => { callback(step); }, span * index * 1000); });
}

export default {
  name: "ScoreTag",
  components: { Star },
  props: {
    height: { type: Number, required: true },
    isExchangable: { type: Boolean, default: false },
  },
  data: () => ({
    display_score: 0,
  }),
  computed: {
    score() { return this.$store.getters.user.score || 0; },
    user() { return this.$store.state.user; },
  },
  methods: {
    async add() {
      if (!this.user) { return; }
      this.$refs.tag.classList.add("show");
      this.$refs.star.$el.classList.add("adding");
      await delay(0.7);
      this.$refs.score.classList.add("adding");
      const sound = this.score > this.display_score ? "soundEffects/getting-star" : "soundEffects/spend";
      // add score animation
      gradient(this.display_score, this.score, 1, (n) => {
        this.display_score = n;
      });
      // add score sound effect
      gradient(this.display_score, this.score, 1, () => {
        this.$audio.sounds[sound].stop();
        this.$audio.sounds[sound].play();
      }, { limit: 20 });
      await delay(0.5);
      this.$refs.score.classList.remove("adding");
      await delay(1);
      this.$refs.star.$el.classList.remove("adding");
      this.$refs.tag.classList.remove("show");
    },
    exchange() {
      if (!this.isExchangable) { return; } // only available at Home View
      this.$store.dispatch(action.setNotification, { type: "bill" });
    },
  },
  watch: {
    score() { this.add(); },
  },
  mounted() {
    this.display_score = this.$store.getters.user.score;
    ["getting-star", "spend"].forEach((id) => {
      if (!(`soundEffects/${id}` in this.$audio.sounds) && soundEffects.get(id)) {
        this.$audio.register({ id: `soundEffects/${id}`, src: soundEffects.get(id) });
      }
    });
  },
};
</script>
<template> <!-- eslint-disable -->
  <div id="tag" :style="{'--total-height': height + 'rem'}" ref="tag">
      <div id="circle"><Star class="star" ref="star"/></div>
      <div id="score" ref="score" :class="{'cursor-pointer': isExchangable}" @click="exchange">
        {{ display_score }}
      </div>
  </div>
</template>
<style scoped lang="css">
#tag {
  @apply fixed right-0 top-20 pr-5 bg-white rounded-l-full flex items-center shadow-md
  transform transition-transform duration-700 select-none;
  min-width: 6rem;
  --redio: 0.6;
  height: calc(var(--total-height) * var(--redio));
  --score-min-width: calc(var(--total-height) * 0.5 * 2  * var(--redio));
  --tw-translate-x: calc(var(--score-min-width) + 2rem);
}
#tag.show { --tw-translate-x: 0; }
#tag:hover, #tag:focus, #tag:active { --tw-translate-x: 0; }
#circle {
  @apply shadow-inner rounded-full overflow-hidden bg-gray-50
  flex justify-center items-center;
  height: 80%;
  width: calc(var(--total-height) * 0.8 * var(--redio));
  margin-left: calc(var(--total-height) * 0.1 * var(--redio));
}
#star {
  @apply text-yellow-300 h-4/5 w-4/5 relative pb-0.5;
  stroke: white;
  stroke-width: 0.1rem;
  paint-order: stroke fill;
}
#star.adding {
  animation: spin 1.5s cubic-bezier(0.4, 0, 0.2, 1) 1;
}
#score {
  @apply text-gray-800 mx-3 text-center transform transition-transform;
  line-height: calc(var(--total-height) * 0.8  * var(--redio));
  font-size: calc(var(--total-height) * 0.5  * var(--redio));
  min-width: var(--score-min-width);
}
#score.adding {
  @apply scale-125;
}

/* on tablet */
@media (min-width: 768px) {
  #tag { --redio: 0.8; }
}

/* on pc */
@media (min-width: 1024px) {
  #tag {
    @apply top-6;
    --redio: 1;
  }
}

</style>
