import Vue from "vue";
import { getDoc, onSnapshot } from "firebase/firestore";
import { setData, getImageURL } from "@/apis/firebase";

let lastUpdate;

const initialState = () => ({
  total: 0,
  forceUpdateGetter: 0,
  unsubscribes: new Map(),
});

const games = {
  state: initialState,
  actions: {
    async getGame({ state, dispatch }, { reference }) {
      if (!reference || reference.id in state) { return; }
      const snap = await getDoc(reference);
      if (!snap.exists()) { return; }
      // mount game
      dispatch("mountGame", { data: snap.data(), reference });
    },
    syncGame({ state, dispatch, commit }, { reference }) {
      if (!reference || state.unsubscribes.has(reference.id)) { return; }
      const unsubscribe = onSnapshot(reference, async (snap) => {
        const now = Date.now();
        // ignore change while game just updated
        if (lastUpdate && (now - lastUpdate < 5000)) { return; }
        if (!snap.exists()) { return; }
        dispatch("mountGame", { data: snap.data(), reference });
      });
      commit("addUnsubscribe_Game", { key: reference.id, unsubscribe });
    },
    async mountGame({ rootState, commit }, { data, reference }) {
      const game = data;
      if (!game.timestamp || game.deleted) { return; }
      if (game.imgURI) {
        const imgURL = await getImageURL(game.imgURI);
        if (imgURL.error) { console.log(imgURL.error); } else {
          game.imgURL = imgURL.data;
          delete game.imgURI;
        }
      }
      game.ref = reference;
      const isCurrentGame = game.ref.id === rootState.game;
      commit("addGame", { id: game.ref.id, game, isCurrentGame });
    },
    updateGame({ commit }, payload) {
      commit("updateGame", payload);
      lastUpdate = Date.now();
      setData({ ...payload.update }, payload.ref);
    },
    removeGame({ commit }, payload) {
      commit("removeGame", payload);
      lastUpdate = Date.now();
    },
  },
  mutations: {
    addGame(state, payload) {
      if (!state[payload.id]) { state.total += 1; } // refresh getters officialGames...
      if (payload.isCurrentGame) { state.forceUpdateGetter += 1; } // reflash the getters.game
      state[payload.id] = payload.game;
    },
    addUnsubscribe_Game(state, { key, unsubscribe }) { state.unsubscribes.set(key, unsubscribe); },
    updateGame(state, payload) {
      if (state[payload.ref.id]) {
        state.forceUpdateGetter += 1; // reflash the getters.game
        state[payload.ref.id] = { ...state[payload.ref.id], ...payload.update };
      }
    },
    removeGame(state, payload) {
      Vue.delete(state, payload.game.ref.id);
    },
    resetGames(state) {
      // Release firestore subscribe
      state.unsubscribes.forEach((unsubscribe) => unsubscribe());
      // Reset
      const newState = initialState();
      Object.keys(state).forEach((key) => {
        if (Object.keys(newState).includes(key)) {
          state[key] = newState[key];
        } else {
          delete state[key];
        }
      });
    },
  },
};

export default games;
