import firebase from "@/plugins/firebase";
import { getUid } from "@/apis/auth";
import {
  getFirestore, doc, updateDoc, arrayUnion, serverTimestamp, onSnapshot,
} from "firebase/firestore";

const db = getFirestore(firebase);

export async function appendLoaded(quizzes) {
  const FLOW = doc(db, "users", getUid(), "statuses", "current");

  await updateDoc(FLOW, {
    loaded: arrayUnion(...quizzes),
    lastUpdate: serverTimestamp(),
  });
}

export function subscribePreloadQuizzes(callback) {
  const STREAM = doc(db, "users", getUid(), "stream", "preloadQuizzes");
  const unsubscribe = onSnapshot(STREAM, (snapshot) => {
    if (snapshot.exists()) {
      callback(snapshot.data());
    } else {
      callback({});
    }
  });
  return unsubscribe;
}
