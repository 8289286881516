import "firebase/storage";

const getters = {
  user(state) {
    let user = {
      name: "訪客",
      photoURL: require("@/assets/user.svg"),
      role: "guest",
      ownGames: [],
      joinedGames: [],
      config: {},
      score: 0,
      tags: {},
    };
    if (!state.user) { user.isValid = true; return user; }
    const defaultConfig = {
      typable: true,
      draggable: true,
      isBeginner: false,
      phonicsLevel: "ShortVowels",
    };
    user = { ...user, ...state.user };
    user.config = { ...defaultConfig, ...state.user.config };
    user.name = user.displayName;
    user.expirationDate = user.expirationDate ? user.expirationDate.toDate() : null;
    delete user.displayName;
    const today = new Date();
    const expiration = new Date(user.expirationDate);
    if (expiration) { expiration.setDate(expiration.getDate() + 1); }
    switch (state.user.role) {
      case "admin":
        user.isValid = true; break;
      case "teacher":
        user.isValid = true; break;
      case "student":
        user.isValid = expiration ? expiration >= today : false; break;
      case "guest":
        user.isValid = expiration ? expiration >= today : false; break;
      default:
        break;
    }
    return user;
  },
  isUserAuth(state) {
    return !!state.user;
  },
  myTags(state) {
    if (!state.user || !state.user.tags) { return []; }
    const allTags = new Set();
    Object.values(state.user.tags).forEach((tags) => {
      tags.forEach((tag) => { allTags.add(tag); });
    });
    return [...allTags].sort();
  },
  game(state) {
    let game = {
      config: { stage: {} },
      fellowers: [],
      public: false,
      stageList: [],
    };
    if (state.games.forceUpdateGetter < 0) { return null; }
    if (state.games[state.game]) { game = { ...game, ...state.games[state.game] }; }
    return game;
  },
  officialGames(state) {
    const games = [];
    if (state.games.total < 0) { return games; }
    state.officialGames.forEach((ref) => { games.push(state.games[ref.id]); });
    return games;
  },
  ownGames(state) {
    const games = [];
    if (!state.user || !state.user.ownGames || state.games.total < 0) { return games; }
    state.user.ownGames.forEach((ref) => {
      const game = state.games[ref.id];
      if (game && !game.hasDeleted) { games.push(game); }
    });
    return games;
  },
  joinedGames(state) {
    const games = [];
    if (!state.user || !state.user.joinedGames || state.games.total < 0) { return games; }
    state.user.joinedGames.forEach((ref) => {
      const game = state.games[ref.id];
      if (game && !game.hasDeleted) { games.push(game); }
    });
    return games;
  },
  graphemes(state) {
    const graphemes = {};
    Object.entries(state.phonemes).forEach(([phoneme, value]) => {
      Object.entries(value.example).forEach(([grapheme, example]) => {
        if (grapheme in graphemes) {
          graphemes[grapheme].phonemes[phoneme] = example;
        } else {
          graphemes[grapheme] = { phonemes: { [phoneme]: example } };
        }
      });
    });
    // add double letters
    ["b", "c", "d", "f", "g", "k", "l", "m", "n", "p", "r", "s", "t", "z"].forEach((x) => { graphemes[`${x}2`] = { ...graphemes[x] }; });
    // check grapheme is closer or not
    Object.keys(graphemes).forEach((grapheme) => {
      const newList = Object.keys(graphemes).filter((letter) => {
        if (letter.length >= grapheme.length) {
          return letter.slice(0, grapheme.length) === grapheme;
        } return false;
      });
      const data = graphemes[grapheme];
      data.closer = !(newList.length > 1);
      // mono-, di, or multigraph

      switch (grapheme.length) {
        case 2: data.digraph = true; break;
        case 1: data.monograph = true; break;
        default: data.multigraph = true;
      }
    });
    return graphemes;
  },
  validInput(state) {
    const validInput = { q: null };
    Object.values(state.phonemes).forEach((value) => {
      Object.keys(value.example).forEach((grapheme) => {
        if (!(grapheme in validInput)) {
          for (let i = grapheme.length; i > 0; i--) {
            validInput[grapheme.slice(0, i)] = null;
            if (i === 2) { break; }
          }
        }
      });
    });
    return validInput;
  },
  phonemeMask(state) {
    const mask = {};
    const symbol = state.user.config ? state.user.config.phoneticSymbol || "IPA" : "IPA";
    Object.entries(state.phonemes).forEach(([phoneme, value]) => {
      mask[phoneme] = value[symbol];
    });
    return mask;
  },
  config(state) {
    // default config setting
    const config = {
      user: {},
      game: { stage: {} },
      stage: {},
      mission: {
        HPMax: 5,
        HPGoal: 2, // Amount of HP lost
        timeMax: 5 * 60 * 1000,
        timeGoal: 2.5 * 60 * 1000,
        victoryHP: 0, // Amount of HP lost
        victoryTime: 1.5 * 60 * 1000,
      },
    };
    if (state.user && state.user.config) { config.user = state.user.config; }
    if (state.games && state.games[state.game] && state.games[state.game].config) {
      config.game = state.games[state.game].config;
    }
    ["stage", "mission"].forEach((part) => {
      const id = state.config[part];
      if (id && state[`${part}s`][id] && state[`${part}s`][id].config) {
        config[part] = { ...config[part], ...state[`${part}s`][id].config };
      }
    });
    return config;
  },
  nextMission(state) {
    const currentStage = state.config.stage;
    const currentMission = state.config.mission;
    if (!currentStage || !currentMission) { return {}; }
    let foundCurrentStage = false; let foundCurrentMission = false; let m = 0;
    const stages = state.games[state.game].stageList;
    while (m < stages.length) {
      const stage = stages[m].id;
      if (!foundCurrentStage && stage === currentStage) {
        foundCurrentStage = true;
      }
      if (foundCurrentStage) {
        // if next mission in stage
        let n = 0;
        const { missions } = state.stages[stage];
        while (n < missions.length) {
          const mission = missions[n].id;
          if (!foundCurrentMission) {
            if (mission === currentMission) { foundCurrentMission = true; }
          } else {
            const data = state.missions[mission];
            if (data) { return { mission, stage }; }
          }
          n += 1;
        }
      }
      m += 1;
    }
    return {};
  },
  playerData(state) {
    let data = {
      missions: {},
      onPlaying: null,
      lastPlayed: null,
    };
    data = { ...data, ...state.playerData };
    return data;
  },
  defaultPhonemes(state) {
    let defaultPhonemes = {};
    const obj = state.defaultPhonemes;
    if (obj) {
      defaultPhonemes = obj.US;
      if (state.user && state.user.config && state.user.config.accent === "Britain") {
        defaultPhonemes = { ...obj.US, ...obj.UK };
      }
    }

    return new Map(Object.entries(defaultPhonemes));
  },
};
export default getters;
