import firebase from "@/plugins/firebase";
import {
  getStorage, ref, uploadBytes, getDownloadURL,
} from "firebase/storage";

const db = getStorage(firebase);

export async function getFileURL(path) {
  if (path === null) { return null; }
  try {
    const url = await getDownloadURL(ref(db, path));
    return { data: url };
  } catch (error) { return { error }; }
}

export async function saveFile(filePath, file) {
  try {
    const fileRef = ref(db, filePath);
    const fileSnapshot = await uploadBytes(fileRef, file);
    const URI = `gs://${fileSnapshot.ref.bucket}/${fileSnapshot.ref.fullPath}`;
    return { data: { URI } };
  } catch (error) { return { error }; }
}
