import firebase from "@/plugins/firebase";
import {
  getFirestore, doc, collection, getDoc, getDocs, setDoc, addDoc, updateDoc, deleteDoc,
  serverTimestamp, writeBatch, query, where, limit,
} from "firebase/firestore";
import { getUid } from "./auth";
import { getFileURL, saveFile } from "./storage";

export const db = getFirestore(firebase);

function rename(name, file) {
  return `${name}.${file.type.split("/")[1]}`;
}

export function pathToReference(path) { return doc(db, path); }
export function getImageURL(path) { return getFileURL(path); }
export function getSoundURL(path) { return getFileURL(path); }

export async function decorateQuestion(question) {
  const data = question;
  async function replaceURI(uri, onSuccess) {
    if (!uri) { return; }
    const url = await getFileURL(uri);
    if (!url) { return; }
    onSuccess(url);
  }
  const tasks = [
    replaceURI(data.imgURI, (url) => { data.imgURL = url.data; delete data.imgURI; }),
    replaceURI(data.soundURI, (url) => { data.soundURL = url.data; delete data.soundURI; }),
  ];
  if (data.example && data.example.readURI) {
    tasks.push(replaceURI(
      data.example.readURI,
      (url) => { data.example.readURL = url.data; delete data.example.readURI; },
    ));
  }
  if (data.example && data.example.readClozeURI) {
    tasks.push(replaceURI(
      data.example.readClozeURI,
      (url) => { data.example.readClozeURL = url.data; delete data.example.readClozeURI; },
    ));
  }

  await Promise.all(tasks);
  return data;
}

export async function getQuestion(reference) {
  const docSnap = await getDoc(reference);
  if (docSnap.exists()) { return docSnap.data(); }
  return null;
}

export async function uploadFile(file, name, folder, payload = {}) {
  const uid = getUid();
  // Set the path of the file to Cloud Storage.
  const fileName = rename(name, file);
  // file type
  let filetype = "";
  if (file.type.match("image.*")) {
    filetype = "image";
  } else if (file.type.match("audio.*")) {
    filetype = "audio";
  }
  // path
  let path = "";
  if (folder === "questions" && payload.game && payload.questionId) {
    path = `${payload.game}/${folder}/${payload.questionId}`;
  } else if (folder === "backgrounds" && payload.game) {
    path = `${payload.game}/${folder}`;
  } else if (folder === "phonemes") {
    path = `${folder}`;
  }

  const filePath = `${filetype}s/${uid}/${path}/${fileName}`;
  // Upload the image to Cloud Storage.
  const result = await saveFile(filePath, file);
  return result;
}

export async function getUserPhoneticFile(lang) {
  const uid = getUid();
  const docRef = doc(db, "languages", lang, "authors", uid);
  try {
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) { return {}; }
    const phoneticSounds = docSnap.data();
    return phoneticSounds;
  } catch (error) { console.log(error); return {}; }
}

export async function setUserPhoneticFile(lang, data) {
  const uploadData = data;
  const uid = getUid();
  const docRef = doc(db, "languages", lang, "authors", uid);
  uploadData.timestamp = serverTimestamp();
  setDoc(docRef, uploadData);
}

export async function setData(updateData, DocRef) {
  const data = updateData;
  data.timestamp = serverTimestamp();
  await updateDoc(DocRef, data);
}

export async function addQuestion(text, mission) {
  let docRef;
  const gameId = mission.ref.parent.parent.id;
  const questionsREF = collection(db, "games", gameId, "questions");
  const queryExistQusertion = query(questionsREF, where("text", "==", text));
  const queryResults = await getDocs(queryExistQusertion);
  if (queryResults.empty) {
    const capitalLetter = text.slice(0, 1);
    docRef = await addDoc(questionsREF, {
      text,
      spelling: [],
      capitalized: capitalLetter === capitalLetter.toUpperCase(),
      timestamp: serverTimestamp(),
    });
  } else {
    docRef = queryResults.docs[0].ref;
  }
  // add question to mission's questions list
  const updateData = { questions: mission.questions.slice() };
  updateData.questions.push({ id: docRef.id, text, ref: docRef });
  updateData.timestamp = serverTimestamp();
  updateDoc(mission.ref, updateData);
  return docRef;
}

export async function addMission(data, stage) {
  const gameId = stage.ref.parent.parent.id;
  const folderRef = collection(db, "games", gameId, "missions");
  // create new mission
  const mission = {
    ...data,
    created: serverTimestamp(),
  };
  const missionRef = await addDoc(folderRef, mission);
  // add the mission to stage
  const updateData = { missions: stage.missions.slice() };
  updateData.missions.push(missionRef);
  setData(updateData, stage.ref);
  return missionRef;
}

export async function addStage(game) {
  const folderRef = collection(db, "games", game.ref.id, "stages");
  // create new stage
  const stage = {
    title: "",
    missions: [],
    config: { text: { color: "white" }, provideOptions: true },
  };
  const stageRef = await addDoc(folderRef, stage);
  // add the stage to game
  const updateData = { stageList: game.stageList.slice() };
  updateData.stageList.push(stageRef);
  setData(updateData, game.ref);
  return stageRef.id;
}

async function copyCollection(srcGameName, destGameName, collectionName) {
  const REBASE = { missions: "questions", stages: "missions" };
  const srcCollectionREF = collection(db, "games", srcGameName, collectionName);
  const querySnapshot = await getDocs(srcCollectionREF);
  const writeBatchs = [];
  querySnapshot.docs.forEach((docSnap, index) => {
    if (index % 400 === 0) { writeBatchs.push(writeBatch(db)); }
    const batch = Math.floor(index / 400);
    function rebase(refList) {
      const newList = [];
      refList.forEach((docRef) => {
        newList.push(doc(db, "games", destGameName, REBASE[collectionName], docRef.id));
      });
      return newList;
    }
    const data = docSnap.data();
    if (collectionName in REBASE) {
      data[REBASE[collectionName]] = rebase(data[REBASE[collectionName]]);
    }
    writeBatchs[batch].set(doc(db, "games", destGameName, collectionName, docSnap.id), data);
  });
  const tasks = [];
  writeBatchs.forEach((batch) => { tasks.push(batch.commit()); });
  await Promise.all(tasks);
  console.log("Firebase batch operation completed.");
}

export async function addGame(name, user, payload = {}) {
  const uid = getUid();
  const folderRef = collection(db, "games");
  // create new game
  const game = {
    name,
    owner: uid,
    public: false,
    stageList: [],
    created: serverTimestamp(),
    timestamp: serverTimestamp(),
  };
  const gameRef = await addDoc(folderRef, game);
  // add the game to user
  if (uid === "official") { return gameRef.id; }
  const updateUserData = { ownGames: user.ownGames.slice() };
  updateUserData.ownGames.push(gameRef);
  setData(updateUserData, user.ref);
  // game cloning
  if (!payload.origin) { return gameRef.id; }
  ["questions", "missions", "stages"].forEach((folder) => {
    copyCollection(payload.origin.ref.id, gameRef.id, folder);
  });
  const originDoc = await getDoc(payload.origin.ref);
  const originData = originDoc.data();
  const updateNewGameData = {
    parent: payload.origin.ref,
    stageList: [],
  };
  if (originData.config) { updateNewGameData.config = originData.config; }
  if (originData.imgURI) { updateNewGameData.imgURI = originData.imgURI; }
  payload.origin.stageList.forEach((stage) => {
    updateNewGameData.stageList.push(doc(db, "games", gameRef.id, "stages", stage.id));
  });
  setData(updateNewGameData, gameRef);
  return gameRef.id;
}

export function deleteGame(game, user) {
  const gameId = game.ref.id;
  // // the function of deleting all files in the folder
  // function deleteFolder(folderRef) {
  //   folderRef.listAll().then((res) => {
  //     res.prefixes.forEach((ref) => { deleteFolder(ref); });
  //     // const promises = res.items.map((item) => item.delete());
  //     const promises = res.items.map((item) => console.log(item));
  //     Promise.all(promises);
  //   });
  // }
  // // delete all images in the game
  // const desertImagesREF = storageREF.ref(`images/${userId}/${gameId}`);
  // const desertAudiosREF = storageREF.ref(`audios/${userId}/${gameId}`);
  // deleteFolder(desertImagesREF);
  // deleteFolder(desertAudiosREF);

  // mark deleted tag to the game
  setData({ deleted: serverTimestamp() }, game.ref);
  // remove the game from user ownGames
  const updateData = { ownGames: [] };
  user.ownGames.forEach((gm) => { if (gm.id !== gameId) { updateData.ownGames.push(gm); } });
  setData(updateData, user.ref);
}

export function deleteData(reference) {
  deleteDoc(reference).then(() => {
    console.log("Document successfully deleted!");
  }).catch((error) => {
    console.error("Error removing document: ", error);
  });
}

export async function getRecommendedText(gameREF, keyword, graphemes, phonemes) {
  let q;
  const questionsREF = collection(db, "games", gameREF.id, "questions");
  const recommendTexts = [];
  if (keyword === "") { return recommendTexts; }
  if (keyword.at(0) === "/") {
    let phoneme = keyword.slice(1).trim();
    if (`${phoneme}ː` in phonemes) { phoneme = `${phoneme}ː`; }
    if (phoneme in phonemes) {
      q = query(questionsREF, where("IPA", "array-contains", phoneme), limit(50));
    } else { return recommendTexts; }
  } else if (keyword.includes("/")) {
    let [grapheme, phoneme] = keyword.split("/");
    grapheme = grapheme.trim();
    phoneme = phoneme.trim();
    if (`${phoneme}ː` in phonemes) { phoneme = `${phoneme}ː`; }
    if (grapheme in graphemes && phoneme in phonemes) {
      q = query(questionsREF, where("keys", "array-contains", `${grapheme}/${phoneme}`), limit(50));
    } else { return recommendTexts; }
  } else if (keyword in graphemes) {
    q = query(questionsREF, where("spelling", "array-contains", keyword), limit(50));
  } else {
    q = query(questionsREF, where("text", ">=", keyword), where("text", "<=", (`${keyword}\uf8ff`)), limit(50));
  }
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((question) => {
    recommendTexts.push({ ...question.data(), id: question.id });
  });
  return recommendTexts;
}

export async function getGameInfo(gameId) {
  const data = await getDoc(doc(db, "games", gameId));
  if (data.exists()) {
    return { ...data.data(), ref: data.ref };
  }
  return null;
}

export function joinGame(game, user) {
  const joinedGames = user.joinedGames || [];
  joinedGames.push(game.ref);
  const fellowers = game.fellowers || [];
  fellowers.push(user.ref);
  return Promise.all([setData({ joinedGames }, user.ref), setData({ fellowers }, game.ref)]);
}

export async function record(log) {
  try {
    const reference = await addDoc(collection(db, "records"), {
      ...log,
      timestamp: serverTimestamp(),
    });
    return reference;
  } catch (error) {
    console.log("record data:", log);
    console.error(error);
    return null;
  }
}

export async function getUserProfileConfig() {
  const data = await getDoc(doc(db, "config", "userProfile"));
  if (data.exists()) {
    return { ...data.data() };
  }
  return null;
}

export function updateFlowStatus(status, user, { snapshot = false, initialize = false } = {}) {
  if (snapshot) {
    const date = new Date();
    const docId = `snap${date.getFullYear()}${(`0${date.getMonth() + 1}`).slice(-2)}${(`0${date.getDate()}`).slice(-2)}`;
    const data = { ...status, timestamp: serverTimestamp() };
    setDoc(doc(db, "users", user.ref.id, "statuses", docId), data);
  } else {
    if (!status) { return; } // prevent abnormal update
    if (initialize) {
      const data = { ...status, created: serverTimestamp() };
      setDoc(doc(db, "users", user.ref.id, "statuses", "current"), data);
    } else {
      setData(status, doc(user.ref, "statuses", "current"));
    }
  }
}

export async function queryUserReports(userId, from, to) {
  const reports = new Map();
  const collectionREF = collection(db, "users", userId, "reports");
  const reportsSnapshot = await getDocs(query(
    collectionREF,
    where("start", ">=", from),
    where("start", "<=", to),
  ));
  reportsSnapshot.forEach((report) => { reports.set(report.id, report.data()); });
  return reports;
}

export function reportUserLogin(userId) {
  const today = new Date();
  const year = `${today.getFullYear()}`;
  const month = today.getMonth() >= 9 ? `${today.getMonth() + 1}` : `0${today.getMonth() + 1}`;
  const day = today.getDate() > 9 ? `${today.getDate()}` : `0${today.getDate()}`;
  const date = year + month + day;
  const docREF = doc(db, "dailyActiveUsers", date);
  const data = {};
  data[userId] = serverTimestamp();
  setDoc(docREF, data, { merge: true });
}
