import Vue from "vue";
import { subscribePreloadQuizzes } from "@/apis/firestore/user/flow";
import gameStream from "@/apis/stream";
import { pathToReference } from "@/apis/firebase";

// let nextByTimeQuizTimer;

const initialState = () => ({
  onWaiting: false,
  streamUnsubscribe: null,
  word: undefined,
  onListeningPreload: false,
  amountToReview: 0,
});

const stream = {
  namespaced: true,
  state: initialState,
  getters: {
    needMoreQuizzes(state) { return state.word === null; },
  },
  actions: {
    async getQuiz({
      rootState, rootGetters, commit, dispatch,
    }, { audioRegister, retry = false }) {
      const idToken = rootState.idToken || await dispatch("getIdToken", {}, { root: true });
      if (!rootState.idToken) { return; }
      commit("requestStart");
      try {
        const response = await gameStream({
          userId: rootState.user.ref.id,
          idToken,
          level: rootGetters.user.config.EnglishLevel
          || (rootGetters.user.config.isBeginner ? 1 : 2),
          dragOnly: !rootGetters.user.config.typable,
          hasloadedQuizzes: rootState.questions.all || [],
        });
        if (Number.isInteger(response.amountToReview)) {
          commit("setStream", { key: "amountToReview", value: response.amountToReview });
        }
        const { word, preload } = response;
        if (word && word.questionPath) {
          word.question = pathToReference(word.questionPath);
          delete word.questionPath;
        }
        commit("setStream", { key: "word", value: word });

        // Mount the Preload Quiz
        if (preload) {
          dispatch("mountQuestion", {
            data: preload,
            reference: word.question,
            audioRegister,
            preload: true,
          }, { root: true });
        }
      } catch (err) {
        if (err.response && err.response.status === 401 && !retry) { // Unauthorized
          await dispatch("getIdToken", {}, { root: true });
          dispatch("getQuiz", { audioRegister, retry: true });
        } else {
          console.log(err);
        }
      }
      commit("requestEnd");
    },
    reportQuiz({
      rootState, rootGetters, commit, dispatch,
    }, { report, audioRegister, retry = false } = {}) {
      if (!report) { return; }
      commit("requestStart");
      gameStream({
        userId: rootState.user.ref.id,
        idToken: rootState.idToken,
        level: rootGetters.user.config.EnglishLevel
            || (rootGetters.user.config.isBeginner ? 1 : 2),
        dragOnly: !rootGetters.user.config.typable,
        hasloadedQuizzes: rootState.questions.all || [],
      }, report).then((response) => {
        if (Number.isInteger(response.amountToReview)) {
          commit("setStream", { key: "amountToReview", value: response.amountToReview });
        }
        const { word, preload } = response;
        if (word && word.questionPath) {
          word.question = pathToReference(word.questionPath);
          delete word.questionPath;
        }
        commit("setStream", { key: "word", value: word });

        // Mount the Preload Quiz
        if (preload) {
          dispatch("mountQuestion", {
            data: preload,
            reference: word.question,
            audioRegister,
            preload: true,
            isReady: true,
          }, { root: true });
        }
      }).catch(async (err) => {
        commit("setStream", { key: "word", value: null });
        if (err.response && err.response.status === 401 && !retry) { // Unauthorized
          await dispatch("getIdToken", {}, { root: true });
          dispatch("reportQuiz", { report, audioRegister, retry: true });
        }
      }).finally(() => {
        commit("requestEnd");
      });
    },
    connectPreloadQuizzes({ state, commit, dispatch }, { audioRegister }) {
      if (state.onListeningPreload) { return; }
      const unsubscribe = subscribePreloadQuizzes((quizzes) => {
        if (!state.onListeningPreload) {
          commit("setStream", { key: "onListeningPreload", value: true });
          // skip first preload data
          return;
        }
        Object.values(quizzes).forEach(({ reference, data }) => {
          dispatch("mountQuestion", {
            data,
            reference,
            audioRegister,
            preload: true,
            isReady: true,
          }, { root: true });
        });
      });
      commit("setStream", { key: "streamUnsubscribe", value: unsubscribe });
    },
  },
  mutations: {
    setStream(state, { key, value }) {
      Vue.set(state, key, value);
    },
    requestStart(state) { state.onWaiting = true; },
    requestEnd(state) { state.onWaiting = false; },
  },
};

export default stream;
