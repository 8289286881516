import firebase from "@/plugins/firebase";
import {
  getFirestore, collection, addDoc, updateDoc,
  query, where, orderBy, limit, startAfter, getDocs, onSnapshot, serverTimestamp,
} from "firebase/firestore";
// doc, getDoc,  setDoc, deleteDoc,
// , writeBatch,
import { getUid } from "../auth";
import { saveFile } from "../storage";

const db = getFirestore(firebase);
const QUIZZES = collection(db, "quizzes");

export async function createQuiz(text, accent, wordType) {
  const uid = getUid();
  const capitalized = text.slice(0, 1) === text.slice(0, 1).toUpperCase();
  const newQuizRef = await addDoc(QUIZZES, {
    text,
    accent,
    wordType,
    owner: uid,
    spelling: [],
    IPA: [],
    keys: [],
    fakeOptions: [],
    example: {},
    capitalized,
    completed: false,
    exist: true,
    config: {},
    create: serverTimestamp(),
    lastUpdate: serverTimestamp(),
  });
  return newQuizRef.id;
}

export async function isTheSameWordExists(text) {
  const uid = getUid();
  const q = query(
    QUIZZES,
    where("text", "==", text),
    where("owner", "==", uid),
    where("exist", "==", true),
    limit(1),
  );
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
}

function setFilter(params) {
  const filters = [];
  if (params.owner) {
    if (params.owner === "myself") {
      filters.push(where("owner", "==", getUid()));
    } else {
      filters.push(where("owner", "==", params.owner));
    }
  }
  if (params.public) {
    filters.push(where("public", "==", true));
  }
  if (params.completed !== undefined) {
    filters.push(where("completed", "==", params.completed));
  }
  if (params.accent) {
    filters.push(where("accent", "==", params.accent));
  }
  if (params.wordType) {
    filters.push(where("wordType", "==", params.wordType));
  }
  if (params.tense) {
    filters.push(where("tense", "==", params.tense));
  }
  filters.push(where("exist", "==", true));
  return filters;
}

export function queryAllQuizzes(filters, callback) {
  let q = query(
    QUIZZES,
    ...setFilter(filters || {}),
    orderBy("lastUpdate", "desc"),
    limit(30),
  );
  if (filters && filters.lastVisible) {
    q = query(q, startAfter(filters.lastVisible));
  }
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(snapshot.docs);
  });
  return unsubscribe;
}

export function queryQuizzesByKeyword(keyword, filters, callback) {
  let q = query(
    QUIZZES,
    where("text", ">=", keyword),
    where("text", "<=", (`${keyword}\uf8ff`)),
    ...setFilter(filters || {}),
    limit(30),
  );
  if (filters && filters.lastVisible) {
    q = query(q, startAfter(filters.lastVisible));
  }
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(snapshot.docs);
  });
  return unsubscribe;
}

export function queryQuizzesByIPA(phoneme, filters, callback) {
  let q = query(
    QUIZZES,
    where("IPA", "array-contains", phoneme),
    ...setFilter(filters || {}),
    orderBy("lastUpdate", "desc"),
    limit(30),
  );
  if (filters && filters.lastVisible) {
    q = query(q, startAfter(filters.lastVisible));
  }
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(snapshot.docs);
  });
  return unsubscribe;
}

export function queryQuizzesByGrapheme(grapheme, filters, callback) {
  let q = query(
    QUIZZES,
    where("spelling", "array-contains", grapheme),
    ...setFilter(filters || {}),
    orderBy("lastUpdate", "desc"),
    limit(30),
  );
  if (filters && filters.lastVisible) {
    q = query(q, startAfter(filters.lastVisible));
  }
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(snapshot.docs);
  });
  return unsubscribe;
}

export function queryQuizzesByKeys(grapheme, phoneme, filters, callback) {
  let q = query(
    QUIZZES,
    where("keys", "array-contains", `${grapheme}/${phoneme}`),
    ...setFilter(filters || {}),
    orderBy("lastUpdate", "desc"),
    limit(30),
  );
  if (filters && filters.lastVisible) {
    q = query(q, startAfter(filters.lastVisible));
  }
  const unsubscribe = onSnapshot(q, (snapshot) => {
    callback(snapshot.docs);
  });
  return unsubscribe;
}

export async function uploadFile(file, name, quizId) {
  const uid = getUid();
  const fileName = `${name}.${file.type.split("/")[1]}`; // rename
  // file type
  let filetype = "";
  if (file.type.match("image.*")) {
    filetype = "image";
  } else if (file.type.match("audio.*")) {
    filetype = "audio";
  }
  const filePath = `${filetype}s/${uid}/quiz/${quizId}/${fileName}`;
  // Upload the image to Cloud Storage.
  const result = await saveFile(filePath, file);
  return result;
}

function transformExample(data) {
  const result = data;
  if (result.example) {
    if (result.example.sentence) {
      result["example.sentence"] = result.example.sentence;
    }
    if (result.example.readURI) {
      result["example.readURI"] = result.example.readURI;
    }
    if (result.example.readClozeURI) {
      result["example.readClozeURI"] = result.example.readClozeURI;
    }
    delete result.example;
  }
  return result;
}

export function updateQuiz(updateData, quizRef) {
  const data = transformExample(updateData);
  data.lastUpdate = serverTimestamp();
  return updateDoc(quizRef, data);
}

export function deleteQuiz(quizRef) {
  const data = {
    exist: false,
    lastUpdate: serverTimestamp(),
  };
  return updateDoc(quizRef, data);
}
