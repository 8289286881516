import firebase from "@/plugins/firebase";
import {
  getFirestore, collection, doc, getDoc,
} from "firebase/firestore";

const db = getFirestore(firebase);
const USERS = collection(db, "users");

export function getPlayer(id) {
  return getDoc(doc(USERS, id));
}

export function other() {

}
