<template>
  <div id="app" class="h-screen">
    <router-view class="h-full" style="overflow-y:auto" />
    <Navigation />
    <Notification />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation/index.vue";
import Notification from "@/components/Notification/index.vue";
import auth from "@/mixins/auth";

export default {
  name: "app",
  components: { Navigation, Notification },
  mixins: [auth],
  data: () => ({}),
  computed: {
    ...mapGetters(["user", "isUserAuth"]),
  },
  watch: {
    user() {
      if (!this.user) { return; }
      // Check user is valid
      if (!this.user.isValid) {
        if (this.$store.state.user.expirationDate) {
          const exp = this.$store.state.user.expirationDate.toDate();
          const expString = ` ${exp.getFullYear()} / ${exp.getMonth() + 1} / ${exp.getDate()} `;
          this.sendExpiredAlarm(expString);
        } else {
          this.sendExpiredAlarm();
        }
      }
    },
  },
  destroyed() {
    // Release firestore subscribe
    this.$store.dispatch("resetGame");
    this.$store.state.unsubscribes.forEach((unsubscribe) => unsubscribe());
  },
};
</script>
