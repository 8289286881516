import Vue from "vue";
import { queryUserReports } from "@/apis/firebase";

const initialState = () => ({});

const reports = {
  state: initialState,
  actions: {
    async getUserReports({ commit }, { userId, date = new Date(), period = 30 }) {
      const toDate = new Date(date.setHours(0, 0, 0, 0));
      const fromDate = new Date(date.setDate(date.getDate() - period));
      const data = await queryUserReports(userId, fromDate, toDate);
      const userReports = [];
      let reportId = ""; let reportData;
      const d = new Date(toDate.setDate(toDate.getDate() - 1)); // reports begin from yesterday
      for (let n = 0; n < period; n++) {
        reportId = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        reportData = data.get(reportId) || {
          // default empty report
          total: 0,
        };
        userReports.push({
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          date: d.getDate(),
          ...reportData,
        });
        d.setDate(d.getDate() - 1); // date --
      }
      commit("addUserReports", { userId, userReports });
    },
  },
  mutations: {
    addUserReports(state, { userId, userReports }) {
      Vue.set(state, userId, userReports);
      // state[userId] = userReports;
    },
  },
};

export default reports;
