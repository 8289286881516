import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/Login/index.vue";
import Home from "../views/Home/index.vue";
import UserProfile from "../views/UserProfile/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { authRequired: true },
  },
  {
    path: "/play/flow",
    name: "FlowGame",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "flow-game" */ "../views/FlowGame/index.vue"),
  },
  {
    path: "/play/:mission",
    name: "Game",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "game" */ "../views/Game/index.vue"),
  },
  {
    path: "/user/:id",
    name: "UserProfile",
    meta: {
      authRequired: true,
    },
    component: UserProfile,
  },
  {
    path: "/map",
    name: "FlowMap",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "map" */ "../views/FlowMap/index.vue"),
  },
  {
    path: "/analysis",
    name: "AnalysisReport",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "map" */ "../views/Analysis/index.vue"),
  },
  {
    path: "/edit",
    name: "Edit",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "Edit" */ "../views/Editor/index.vue"),
  },
  {
    path: "/bank",
    name: "WordBank",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "Shop" */ "../views/WordBank/index.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  // redirect to Home while user has logged in
  if (to.name === "Login" && store.state.isLoggedIn) {
    next({ name: "Home" });
  }
  // redirect to Login page if user is not logged in and the page needs auth
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (store.state.isLoggedIn) { next(); } else {
      console.log("You must be logged in to see this page");
      const query = {};
      if (to.path === "/game" && to.query.id && to.query.id !== "official") {
        query.goto = "game"; query.game = to.query.id;
      }
      next({ name: "Login", query });
    }
  } else {
    next();
  }
});

export default router;
