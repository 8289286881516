<script>
export default {
  name: "PopUp",
};
</script>
<template> <!-- eslint-disable -->
  <div id="pop-up" @click.self="$emit('exit')">
      <slot></slot>
  </div>
</template>
<style scoped lang="css">
#pop-up { @apply
  fixed left-0 top-0 h-screen w-screen z-10
  flex justify-center items-center
  bg-black bg-opacity-20
}
</style>
