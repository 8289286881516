import {
  db, getSoundURL, setData, reportUserLogin,
} from "@/apis/firebase";
import {
  doc, getDoc, onSnapshot, setDoc, updateDoc,
} from "firebase/firestore";
import {
  createByEmail, signInByEmail, logOut, getCurrentUser, getUserIdToken,
} from "@/apis/auth";
import { mutation } from "./mutations";

let lastUpdate;

// action-types
export const action = {
  authByEmail: "authByEmail",
  fetchUser: "fetchUser",
  updateUserProfile: "updateUserProfile",
  updataUserConfig: "updataUserConfig",
  signOut: "signOut",
  loadQuestion: "loadQuestion",
  selectGame: "selectGame",
  enterGame: "enterGame",
  getPhonemes: "getPhonemes",
  getPhoneticSounds: "getPhoneticSounds",
  getSoundEffects: "getSoundEffects",
  getStage: "getStage",
  getMission: "getMission",
  getQuestion: "getQuestion",
  getPlayerData: "getPlayerData",
  setNotification: "setNotification",
  setSimpleNotification: "setSimpleNotification",
  setConfig: "setConfig",
  resetGame: "resetGame",
};

// Component 呼叫 Action 形式：
// let obj = await this.$store.dispatch(EVENT, PAYLOAD);
// if (obj.error) errorHandler(obj.error.type)
// elase completedHandler(obj.data)

const actions = {
  async authByEmail({ dispatch }, { email, password, signUp = false }) {
    try {
      if (signUp) { await createByEmail(email, password); }
      if (!signUp) { await signInByEmail(email, password); }
    } catch (error) {
      const script = {
        "auth/weak-password": "密碼強度不足",
        "auth/invalid-email": "無效的電子郵件信箱",
        "auth/email-already-in-use": "電子郵件信箱已被註冊過",
        "auth/user-not-found": "帳號或密碼錯誤",
        "auth/wrong-password": "帳號或密碼錯誤",
        "auth/user-disabled": "此帳戶已停用",
      };
      const notification = {
        icon: "exclamation",
        color: "yellow",
        title: script[error.code],
        subtitle: "",
      };
      dispatch(action.setSimpleNotification, notification);
    }
  },
  fetchUser({ state, commit, dispatch }, user) {
    if (!user) { commit(mutation.clearUser); return null; }
    commit(mutation.login);
    const {
      uid, email, displayName, emailVerified, photoURL,
    } = user;
    const docRef = doc(db, "users", uid);
    return new Promise((resolve, reject) => {
      let resolveOnce = () => {
        resolveOnce = () => {};
        resolve();
        dispatch("getIdToken");
      };

      reportUserLogin(uid); // record user has login once
      const unsubscribe = onSnapshot(docRef, (data) => {
        if (!data.exists()) {
          // initialized user profile data
          const expirationDate = new Date();
          expirationDate.setHours(0, 0, 0, 0); // get date without time
          expirationDate.setDate(expirationDate.getDate() + 14); // add 14 days
          setDoc(docRef, {
            displayName,
            email,
            emailVerified,
            photoURL,
            googlePhotoURL: photoURL || null,
            expirationDate,
            role: "guest",
            joinedGames: [],
            created: new Date(),
          }).catch((error) => {
            console.error("Error signing up: ", error);
          });
        } else {
          // ignore change while user just updated
          const now = Date.now();
          if (lastUpdate && (now - lastUpdate < 5000)) { return; }
          // update user data
          commit(mutation.setUser, data);
          // load official games
          state.officialGames.forEach((reference) => {
            dispatch("getGame", { reference });
          });
          // load user's games
          const userData = data.data();
          if (userData.ownGames) {
            userData.ownGames.forEach((reference) => {
              dispatch("getGame", { reference });
            });
          }
          // load joined games
          if (userData.joinedGames) {
            userData.joinedGames.forEach((reference) => {
              dispatch("getGame", { reference });
            });
          }
        }
        resolveOnce();
      }, reject);
      commit(mutation.addUnsubscribe, unsubscribe);
    });
  },
  updateUserProfile(_, payload) {
    if (!payload) { // init user profile data
      const {
        uid, email, displayName, emailVerified, photoURL,
      } = getCurrentUser();
      updateDoc(doc(db, "users", uid), {
        displayName,
        email,
        emailVerified,
        photoURL,
      });
    } else if (payload.uid) {
      const updateData = {};
      if (payload.photoURL) { updateData.photoURL = payload.photoURL; }
      if (payload.name) { updateData.displayName = payload.name; }
      if (payload.birthDate) { updateData.birthDate = payload.birthDate; }
      updateDoc(doc(db, "users", payload.uid), updateData);
    }
  },
  updataUserConfig({ state, commit }, payload) {
    let config = state.user.config ? JSON.parse(JSON.stringify(state.user.config)) : {};
    config = { ...config, ...payload };
    commit(mutation.setUserConfig, config);
    setData({ config }, state.user.ref);
    lastUpdate = Date.now();
  },
  updateUserTags({ state, commit }, newTags) {
    const prevTags = state.user.tags || {};
    const tags = { ...prevTags, ...newTags };
    commit(mutation.setUserTags, tags);
    setData({ tags }, state.user.ref);
    lastUpdate = Date.now();
  },
  async getIdToken({ commit }) {
    try {
      const token = await getUserIdToken();
      commit(mutation.setIdToken, token);
      return token;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  addScore({ state, getters, commit }, amount) {
    const score = getters.user.score + amount;
    commit("setUserScore", score);
    const updateData = { score };
    if (amount < 0) { // when spend score
      const exchangeHistory = state.user.exchangeHistory || [];
      exchangeHistory.unshift({
        amount,
        timestamp: new Date(),
      });
      exchangeHistory.splice(5); // only store last 5 records
      commit("setUserExchangeHistory", exchangeHistory);
      updateData.exchangeHistory = exchangeHistory;
    }
    setData(updateData, state.user.ref);
  },
  exchangeScore({ state, getters, commit }, { amount, note }) {
    const score = getters.user.score - amount;
    commit("setUserScore", score);
    const updateData = { score };
    const exchangeHistory = state.user.exchangeHistory || [];
    exchangeHistory.unshift({
      amount: -amount,
      timestamp: new Date(),
      note: note || "",
    });
    exchangeHistory.splice(5); // only store last 5 records
    commit("setUserExchangeHistory", exchangeHistory);
    updateData.exchangeHistory = exchangeHistory;
    setData(updateData, state.user.ref);
  },
  async signOut({ commit }) {
    try {
      await logOut();
      commit(mutation.reset);
    } catch (error) { console.log(error); }
  },
  async getPhonemes({ commit }, lang) {
    const docRef = doc(db, "languages", lang);
    try {
      const data = await getDoc(docRef);
      if (!data.exists()) { return; }
      const language = data.data();
      commit(mutation.setPhonemes, language.phonemes);
      commit(mutation.setDefaultPhonemes, language.defaultPhonemes);
    } catch (error) { console.log(error); }
  },
  async getPhoneticSounds({ state, commit }, { lang, author }) {
    if (state.phoneticSounds && state.phoneticSounds[author]) { return; }
    const docRef = doc(db, "languages", lang, "authors", author);
    let phoneticSounds;
    try {
      const data = await getDoc(docRef);
      if (!data.exists()) { return; }
      phoneticSounds = data.data();
    } catch (error) { // FirebaseError: Missing or insufficient permissions.
      console.log(error);
      phoneticSounds = { phonemes: {} };
    }
    const sounds = { [author]: {}, ...state.phoneticSounds };
    const tasks = [];
    Object.values(phoneticSounds.phonemes).forEach((URI) => tasks.push(getSoundURL(URI)));
    const URLs = await Promise.all(tasks);
    Object.keys(phoneticSounds.phonemes).forEach((phoneme, index) => {
      sounds[author][phoneme] = URLs[index] ? URLs[index].data : null;
    });
    commit(mutation.setPhoneticSounds, sounds);
  },
  selectGame({ state, dispatch, commit }, gameId) {
    dispatch(action.getPlayerData, { gameId, userId: state.user.ref.id });
    commit(mutation.setGame, gameId);
  },
  enterGame({ commit }, mission) {
    commit(mutation.setMission, mission);
  },
  async getSoundEffects({ commit }, payload) {
    const soundEffects = payload.soundEffects ? payload.soundEffects : ["uppercase", "lowercase"];
    try {
      const tasks = [];
      soundEffects.forEach((effect) => tasks.push(getSoundURL(`sounds/effects/${effect}.mp3`)));
      const URLs = await Promise.all(tasks);
      const soundEffectsURL = {};
      soundEffects.forEach((item, index) => {
        soundEffectsURL[item] = URLs[index].data;
      });
      commit(mutation.setSoundEffects, soundEffectsURL);
      return { data: soundEffectsURL };
    } catch (error) { return { error }; }
  },
  async getPlayerData({ state, commit }, payload) {
    // unsubscribe previous player's data
    if (state.playerData) { state.playerData.unsubscribe(); }
    // subscribe new player's data
    const docRef = doc(db, "games", payload.gameId, "players", payload.userId);
    const unsubscribe = onSnapshot(docRef, async (snap) => {
      if (!snap.exists()) { commit(mutation.setPlayerData, { unsubscribe }); return; }
      const data = snap.data();
      data.unsubscribe = unsubscribe;
      commit(mutation.setPlayerData, data);
    });
    commit(mutation.addUnsubscribe, unsubscribe);
  },
  updatePlayerData({ state }, payload) {
    const docRef = doc(db, "games", state.game, "players", state.user.ref.id);
    setDoc(docRef, payload.data);
  },
  setNotification({ commit }, payload) {
    commit(mutation.setNotification, payload);
  },
  setSimpleNotification({ dispatch }, payload) {
    function cancel() {
      dispatch(action.setNotification, null);
    }
    const notification = {
      ...payload,
      buttons: [
        { text: "確認", color: "gray", callback: cancel },
      ],
      blur: cancel,
    };
    dispatch(action.setNotification, notification);
  },
  setConfig({ commit, state }, payload) {
    const newConfig = { ...state.config };
    let changed = false;
    ["stage", "mission"].forEach((part) => {
      if (payload[part] && payload[part] !== state.config[part]) {
        newConfig[part] = payload[part]; changed = true;
      }
    });
    if (changed) { commit(mutation.updateConfig, newConfig); }
  },
  resetGame({ commit }) {
    // Reset modules
    commit("resetQuestions");
    commit("resetMissions");
    commit("resetStages");
  },
};

export default actions;
