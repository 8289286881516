import Vue from "vue";
import { getDoc, onSnapshot } from "firebase/firestore";
import { getImageURL, setData } from "@/apis/firebase";
import { preloadImage } from "@/scripts/preload";
import { action } from "../actions";

let lastUpdate;

const initialState = () => ({
  unsubscribes: new Map(),
});

const stages = {
  state: initialState,
  actions: {
    async getStage({ state, dispatch }, { reference, preload = true }) {
      if (!reference || reference.id in state) { return; }
      const snap = await getDoc(reference);
      if (!snap.exists()) { return; }
      // mount stage
      dispatch("mountStage", { data: snap.data(), reference, preload });
    },
    syncStage({ state, dispatch, commit }, { reference, preload = true }) {
      if (!reference || state.unsubscribes.has(reference.id)) { return; }
      const unsubscribe = onSnapshot(reference, async (snap) => {
        const now = Date.now();
        // ignore change while game just updated
        if (lastUpdate && (now - lastUpdate < 5000)) { return; }
        if (!snap.exists()) { return; }
        // mount stage
        dispatch("mountStage", { data: snap.data(), reference, preload });
      });
      commit("addUnsubscribe_Stage", { key: reference.id, unsubscribe });
    },
    async mountStage({ rootState, dispatch, commit }, { data, reference, preload }) {
      const stage = data;
      if (stage.imgURI) {
        const imgURL = await getImageURL(stage.imgURI);
        if (imgURL.error) { console.log(imgURL.error); } else {
          stage.imgURL = imgURL.data;
          delete stage.imgURI;
        }
      }
      stage.missions.forEach((mission) => {
        if (mission.id in rootState.missions) { return; }
        dispatch(action.getMission, { reference: mission });
      });
      stage.ref = reference;
      commit("addStage", { id: reference.id, stage });
      if (preload && stage.imgURL) {
        preloadImage(stage.imgURL, reference.path);
      }
    },
    updateMissionsList({ commit }, payload) {
      commit("updateMissions", payload);
      setData({ missions: payload.missions }, payload.ref);
    },
    updateStage({ commit }, payload) {
      commit("updateStage", payload);
      setData({ ...payload.update }, payload.ref);
    },
    updateStageConfig({ commit, state }, payload) {
      if (!state[payload.ref.id]) { return; }
      const updateData = { config: JSON.parse(JSON.stringify(state[payload.ref.id].config || {})) };
      updateData.config = { ...updateData.config, ...payload.config };
      commit("updateStage", { update: updateData, ref: payload.ref });
      setData(updateData, payload.ref);
    },
  },
  mutations: {
    addStage(state, payload) {
      if (state[payload.id]) {
        state[payload.id] = payload.stage;
      } else {
        Vue.set(state, payload.id, payload.stage);
      }
    },
    addUnsubscribe_Stage(state, { key, unsubscribe }) { state.unsubscribes.set(key, unsubscribe); },
    updateMissions(state, payload) {
      if (state[payload.id]) {
        state[payload.id].missions = payload.missions;
      }
    },
    updateStage(state, payload) {
      if (state[payload.ref.id]) {
        state[payload.ref.id] = { ...state[payload.ref.id], ...payload.update };
        lastUpdate = Date.now();
      }
    },
    addMissionToStage(state, payload) {
      if (state[payload.stage.ref.id]) {
        state[payload.stage.ref.id].missions.push(payload.mission);
        lastUpdate = Date.now();
      }
    },
    removeMissionFromStage(state, payload) {
      if (state[payload.stage.ref.id]) {
        state[payload.stage.ref.id].missions.splice(payload.index, 1);
        lastUpdate = Date.now();
      }
    },
    resetStages(state) {
      // Release firestore subscribe
      state.unsubscribes.forEach((unsubscribe) => unsubscribe());
      // Reset
      const newState = initialState();
      Object.keys(state).forEach((key) => {
        if (Object.keys(newState).includes(key)) {
          state[key] = newState[key];
        } else { delete state[key]; }
      });
    },
  },
};

export default stages;
