import axios from "axios";

const api = axios.create({
  baseURL: "https://asia-northeast1-spellingame.cloudfunctions.net/api",
  // baseURL: "http://127.0.0.1:5001/spellingame/asia-northeast1/api",
  headers: { "Content-Type": "application/json" },
  timeout: 10000,
  responseType: "json",
});

export default api;
