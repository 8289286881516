import api from "./cloudFunctions";

export default async function gameStream({
  userId, idToken, level = 1, dragOnly = false, hasloadedQuizzes = [],
}, report = null) {
  api.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  const data = {
    loaded: hasloadedQuizzes,
    userConfig: { dragOnly, level },
  };
  if (report) { data.report = report; }
  const response = await api.post(`user/${userId}/stream`, data);
  return response.data;
}
