import { db } from "@/apis/firebase";
import { doc } from "firebase/firestore";

export const initialState = () => ({
  user: null,
  isLoggedIn: false,
  officialGames: [doc(db, "games", "official")],
  config: {},
  playerData: null,
  phonemes: null,
  phoneticSounds: {},
  soundEffects: {},
  notification: null,
  position: { menu: { x: 0, y: 0 } },
  unsubscribes: [],
  default: {
    image: "https://firebasestorage.googleapis.com/v0/b/spellingame.appspot.com/o/public%2Fdefault.png?alt=media",
  },
});

export const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
