<template src="./template.html" />
<style scoped src="./style.css"></style>

<script>
import { mapGetters } from "vuex";
import Icon from "@/components/Icon/index.vue";
import auth from "@/mixins/auth";
import detectingMobile from "@/mixins/detectingMobile";

export default {
  name: "NavigationBar",
  components: { Icon },
  data: () => ({
    display: false,
    isFolded: true,
  }),
  computed: {
    ...mapGetters(["user", "isUserAuth"]),
    isTeacher() {
      return this.user.role === "teacher";
    },
  },
  mixins: [auth, detectingMobile],
  methods: {
    toUserProfile() {
      if (this.$route.name === "UserProfile") { return; }
      this.$audio.play({ id: "soundEffects/click" }); // play click sound
      this.$router.replace({ name: "UserProfile", params: { id: this.user.ref.id } });
    },
    fold(boolean) { if (!this.isMobile) { this.isFolded = boolean; } },
  },
};

</script>
