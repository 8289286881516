import firebase from "@/plugins/firebase";
import {
  getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification,
  signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail,
} from "firebase/auth";

const ADMINISTER_ID = "SuehspRgO0OJ95l3jzT61GVkB0T2";
export function getCurrentUser() {
  const { currentUser } = getAuth(firebase);
  return currentUser;
}

export function getUid() {
  const currentUser = getCurrentUser();
  if (!currentUser) { return null; }
  if (currentUser.uid === ADMINISTER_ID) { return "official"; }
  return currentUser.uid;
}

export function signInByGoogle() {
  const provider = new GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  return signInWithPopup(getAuth(firebase), provider);
}

export function createByEmail(email, password) {
  const auth = getAuth(firebase);
  return createUserWithEmailAndPassword(auth, email, password);
}

export function signInByEmail(email, password) {
  const auth = getAuth(firebase);
  return signInWithEmailAndPassword(auth, email, password);
}

export function checkedEmailVerified() {
  const { currentUser } = getAuth(firebase);
  return currentUser ? currentUser.emailVerified : false;
}

export async function sendVerification() {
  const { currentUser } = getAuth(firebase);
  if (!currentUser || !currentUser.email) { return null; }
  let result;
  // const actionCodeSettings = {
  //   url: `https://spellingame.web.app/?email=${currentUser.email}`,
  // };
  try {
    await sendEmailVerification(currentUser);
    result = null;
  } catch (err) {
    result = err;
  }
  return result;
}

export function sendResetEmail(email) {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email);
}

export function logOut() {
  return signOut(getAuth(firebase));
}

export async function getUserIdToken() {
  const currentUser = getCurrentUser();
  if (!currentUser) { return null; }
  const idToken = await currentUser.getIdToken();
  return idToken;
}
