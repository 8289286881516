<template src="./template.html" />
<style scoped src="./style.css"></style>

<script>
import { action } from "@/store/actions";
import { getUserProfileConfig } from "@/apis/firebase";
import Icon from "@/components/Icon/index.vue";
import AvatarSelector from "@/components/AvatarSelector/index.vue";

const DEFAULT_BACKGROUND_URL = "https://firebasestorage.googleapis.com/v0/b/spellingame.appspot.com/o/public%2FBG%2Fbox.png?alt=media";
const defaultUserPhoto = require("@/assets/img/blank-profile.png");

const SCRIPTS = {
  Chinese: {
    role: "身份", guest: "訪客", student: "學生", teacher: "教師", admin: "管理員",
  },
};

export default {
  name: "userProfile",
  components: { Icon, AvatarSelector },
  data: () => ({
    config: { avatars: [] },
    editPhoto: false,
  }),
  computed: {
    isSelf() { return this.$route.params.id === this.$store.state.user.ref.id; },
    today() { return new Date().toISOString().split("T")[0]; },
    user() {
      if (this.isSelf) { // looking self's profile
        return this.$store.getters.user;
      } // looking other's profile
      return {};
    },
    photoURL() { return this.user.photoURL || defaultUserPhoto; },
    name: {
      get() { return this.user.name; },
      set(name) { this.updateUserProfile({ name }); },
    },
    role: {
      get() { return SCRIPTS.Chinese[this.user.role]; },
    },
    expDate() {
      if (!this.user.expirationDate || this.user.role === "teacher") { return "-"; }
      const ED = this.user.expirationDate;
      const year = ED.getFullYear();
      const month = ED.getMonth() + 1 < 10 ? `0${ED.getMonth() + 1}` : ED.getMonth() + 1;
      const date = ED.getDate() < 10 ? `0${ED.getDate()}` : ED.getDate();
      return `${year} - ${month} - ${date}`;
    },
    birthDate: {
      get() { return this.user.birthDate; },
      set(birthDate) { this.updateUserProfile({ birthDate }); },
    },
  },
  async created() {
    // fetch config data
    this.config = await getUserProfileConfig();
    // set background
    document.getElementById("backdrop").style.backgroundImage = `url('${DEFAULT_BACKGROUND_URL}')`;
  },
  methods: {
    updateUserProfile(updateData) {
      this.$store.dispatch(action.updateUserProfile, { uid: this.user.ref.id, ...updateData });
      if (updateData.photoURL) { this.editPhoto = false; }
    },
  },
};
</script>
