import { action } from "@/store/actions";
import { sendVerification, checkedEmailVerified } from "@/apis/auth";

const auth = {
  methods: {
    async signOut(payload = {}) {
      function execute(unsubscribe) {
        return new Promise((resolve) => { unsubscribe(); resolve(); });
      }
      // unsubscribes data
      const unsubscribeList = [
        ...this.$store.state.unsubscribes.map(execute),
      ];
      await Promise.all(unsubscribeList);
      this.$store.commit("resetFlow");
      this.$store.commit("resetGames");
      this.$store.commit("resetStages");
      this.$store.commit("resetMissions");
      this.$store.commit("resetQuestions");
      await this.$store.dispatch(action.signOut);
      this.$store.dispatch(action.setNotification, null);
      if (payload.replacePath) {
        this.$router.replace(payload.replacePath);
      } else { this.$router.go(0); }
      window.location.reload(true);
    },
    checkEmailVerified() {
      if (!this.$store.state.user.emailVerified) {
        if (checkedEmailVerified()) {
          this.$store.dispatch(action.updateUserProfile);
        } else {
          this.sendVerifyEmail();
        }
      }
    },
    sendVerifyEmail() {
      const notification = {
        icon: "exclamation",
        color: "yellow",
        title: "請驗證您的電子郵件信箱",
        subtitle: "驗證信件已寄送至您的Email",
        buttons: [
          { text: "再寄一次", color: "green", callback: this.sendVerifyEmail },
          { text: "重新登入", color: "gray", callback: this.signOut },
        ],
        blur: () => {},
      };
      sendVerification().then((error) => {
        if (error) {
          if (error.code === "auth/too-many-requests") {
            notification.buttons.splice(0, 1);
          } else { console.log(error); }
        }
        this.$store.dispatch(action.setNotification, notification);
      });
    },
    sendExpiredAlarm(expDate) {
      const notification = {
        icon: "exclamation",
        color: "red",
        title: "帳號已過期",
        subtitle: expDate ? `您的帳號已於${expDate}到期。詳情請聯繫開發人員。` : "詳情請聯繫開發人員。",
        buttons: [
          { text: "確認", color: "green", callback: this.signOut },
        ],
        blur: this.signOut,
      };
      sendVerification().then((error) => {
        if (error) {
          if (error.code === "auth/too-many-requests") {
            notification.buttons.splice(0, 1);
          } else { console.log(error); }
        }
        this.$store.dispatch(action.setNotification, notification);
      });
    },
  },
};

export default auth;
