import { getDoc, onSnapshot } from "firebase/firestore";
import { decorateQuestion, setData } from "@/apis/firebase";
import { updateQuiz } from "@/apis/firestore/quiz";
import synthesizeSpeech from "@/apis/text-to-speech";
import AI from "@/apis/AIassistant";
import { preloadImage } from "@/scripts/preload";
import { mutation } from "../mutations";

const lastUpdate = { id: "", time: undefined };

const template = {
  text: "",
  spelling: [],
  IPA: [],
  capitalized: false,
  fakeOptions: [],
  example: {},
  config: {},
};

const initialState = () => ({
  unsubscribes: new Map(),
  all: [],
});

async function preloadQuestionMedias(questionData, reference, audioRegister) {
  try {
    if (questionData.imgURL) {
      preloadImage(questionData.imgURL, reference.path, { uri: questionData.imgURI });
    }
    if (audioRegister) {
      if (questionData.soundURL) {
        audioRegister({
          id: `questions/${reference.id}`,
          src: questionData.soundURL,
          uri: questionData.soundURI,
        });
      }
      if (questionData.example && questionData.example.readURL) {
        audioRegister({
          id: `examples/${reference.id}`,
          src: questionData.example.readURL,
          uri: questionData.readURI,
        });
      }
      if (questionData.example && questionData.example.readClozeURL) {
        audioRegister({
          id: `clozes/${reference.id}`,
          src: questionData.example.readClozeURL,
          uri: questionData.readClozeURI,
        });
      }
    }
  } catch (e) {
    console.log("error", e);
  }
}

const questions = {
  state: initialState,
  actions: {
    async getQuestion(
      { state, dispatch },
      {
        reference, audioRegister, preload = false, force = false,
      } = {},
    ) {
      if (!reference || (!force && reference.path in state)) { return; }
      const snap = await getDoc(reference);
      if (!snap.exists()) { return; }
      // mount Question
      dispatch("mountQuestion", {
        data: snap.data(), reference, audioRegister, preload,
      });
    },
    syncQuestion({ state, dispatch, commit }, { reference, audioRegister, preload = false } = {}) {
      if (!reference || state.unsubscribes.has(reference.path)) { return; }
      const unsubscribe = onSnapshot(reference, (snap) => {
        if (!snap.exists()) { return; }
        // ignore change while question just updated
        const now = Date.now();
        if (lastUpdate.id === reference.id && (now - lastUpdate.time < 5000)) { return; }
        if (!state.all.includes(reference.id)) {
          // mount Question
          dispatch("mountQuestion", {
            data: snap.data(), reference, audioRegister, preload,
          });
        } else {
          dispatch("renewQuiz", {
            updatedData: snap.data(), reference, audioRegister, preload,
          });
        }
      });
      commit("addUnsubscribe_Question", { key: reference.path, unsubscribe });
    },
    async mountQuestion({ state, commit }, {
      data, reference, audioRegister, preload,
    }) {
      // duplicate checking
      if (state.all.includes(reference.id)) { return; }

      const question = (data.imgURL && data.soundURL) ? data : await decorateQuestion(data);
      if (preload) {
        preloadQuestionMedias(question, reference, audioRegister);
      }
      commit(mutation.addQuestion, {
        id: reference.id,
        path: reference.path,
        question: {
          ...JSON.parse(JSON.stringify(template)),
          ...question,
          ref: reference,
        },
      });
    },
    async renewQuiz({ state, commit }, {
      updatedData, reference, audioRegister, preload,
    }) {
      if (!state.all.includes(reference.id)) { return; }

      const updatedMedias = updatedData.updatedMedias || [];
      let quizData = updatedData;
      delete quizData.updatedMedias;
      // remove unchanged media references
      if (quizData.imgURI && !updatedMedias.includes("wordImage")) {
        delete quizData.imgURI;
      }
      if (quizData.soundURI && !updatedMedias.includes("wordSound")) {
        delete quizData.soundURI;
      }
      if (quizData.example) {
        if (quizData.example.readURI && !updatedMedias.includes("exampleSpeech")) {
          delete quizData.example.readURI;
        }
        if (quizData.example.readClozeURI && !updatedMedias.includes("clozeSpeech")) {
          delete quizData.example.readClozeURI;
        }
      }
      quizData = await decorateQuestion(quizData);
      if (preload) {
        preloadQuestionMedias(quizData, reference, audioRegister);
      }
      commit("setQuestion", { updateData: quizData, reference });
    },
    updateQuestion({ commit }, { updateData, reference }) {
      commit("setQuestion", { updateData, reference });
      setData(updateData, reference); // update Question on firestore
    },
    // for Editor V2
    updateQuiz({ commit }, { updateData, reference, lazy = true }) {
      commit("setQuestion", { updateData, reference, lazy });
      return updateQuiz(updateData, reference);
    },
    async synthesizeSpeech({ dispatch, rootState }, {
      quizId, data, params, retry = false,
    }) {
      const idToken = rootState.idToken || await dispatch("getIdToken", {}, { root: true });
      if (!rootState.idToken) { return; }
      try {
        await synthesizeSpeech(rootState.user.ref.id, idToken, quizId, data, params);
      } catch (error) {
        if (error.response.status === 401 && !retry) { // Unauthorized
          await dispatch("getIdToken", {}, { root: true });
          dispatch("synthesizeSpeech", {
            quizId, data, params, retry: true,
          });
        }
      }
    },
    async getClozeSuggestions({ dispatch, rootState }, {
      word, wordType, retry = false,
    }) {
      const idToken = rootState.idToken || await dispatch("getIdToken", {}, { root: true });
      if (!rootState.idToken) { return; }
      try {
        await AI.getClozeSuggestions(idToken, word, wordType);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401 && !retry) { // Unauthorized
          await dispatch("getIdToken", {}, { root: true });
          dispatch("getClozeSuggestions", {
            word, wordType, retry: true,
          });
        }
      }
    },
  },
  mutations: {
    addQuestion(state, payload) {
      state[payload.path] = payload.question;
      state.all.push(payload.id);
    },
    addUnsubscribe_Question(state, { key, unsubscribe }) {
      state.unsubscribes.set(key, unsubscribe);
    },
    resetQuestions(state) {
      // Release firestore subscribe
      state.unsubscribes.forEach((unsubscribe) => unsubscribe());
      // Reset
      const newState = initialState();
      Object.keys(state).forEach((key) => {
        if (Object.keys(newState).includes(key)) {
          state[key] = newState[key];
        } else {
          delete state[key];
        }
      });
    },
    setQuestion(state, { updateData, reference }) {
      if (!reference || !reference.path || !(state[reference.path])) { return; }
      const previousData = state[reference.path];
      const newData = { ...previousData, ...updateData };
      if (updateData.example && !updateData.example.readURL) {
        newData.example.readURL = previousData.example.readURL;
      }
      if (updateData.example && !updateData.example.readClozeURL) {
        newData.example.readClozeURL = previousData.example.readClozeURL;
      }
      state[reference.path] = newData;
    },
  },
};

export default questions;
