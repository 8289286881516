import Vue from "vue";
import { mapState } from "vuex";
// eslint-disable-next-line
import createjs from "script-loader!soundjs/lib/soundjs.min.js";
import soundsUrl from "@AUDIO/soundEffects";
import store from "./store";

const { Sound } = window.createjs ? window.createjs : createjs;

export default new Vue({
  store,
  data: {
    initialized: false,
    mission: null,
    sounds: {},
    waitToPlay: new Set([]),
  },
  computed: {
    ...mapState(["phoneticSounds", "soundEffects"]),
  },
  watch: {
    phoneticSounds(phoneticSounds, prev) {
      Object.entries(phoneticSounds).forEach(([author, phonemes]) => {
        if (prev[author]) { return; }
        Object.entries(phonemes).forEach(([phoneme, url]) => {
          if (url && url.slice(0, 4) === "http") {
            this.register({ id: `phonemes/${phoneme}/${author}`, src: url });
          }
        });
      });
    },
    soundEffects(soundEffects) {
      const sounds = [];
      Object.entries(soundEffects).forEach(([id, url]) => {
        sounds.push({ id: `soundEffects/${id}`, src: url });
      });
      this.register({ sounds });
    },
    mission(mission) {
      const sounds = [];
      mission.questions.forEach((question) => {
        const qData = this.$store.state.questions[question.path];
        const src = qData ? qData.soundURL : undefined;
        if (src) { sounds.push({ id: `questions/${question.id}`, src }); }
      });
      this.register({ sounds });
    },
  },
  methods: {
    play({
      id, collection, sound, callback, author, delay = 0,
    }) {
      if (!this.initialized) { return; }
      setTimeout(() => {
        // define Id
        let soundId = id || undefined;
        if (collection === "phonemes" && sound && author) {
          soundId = `${collection}/${sound}/${author}`;
        } else if (collection && sound) {
          soundId = `${collection}/${sound}`;
        }
        if (!soundId) { return; }
        // play the sound if exists
        let instance;
        if (this.sounds[soundId]) { // the sound is already
          instance = Sound.play(soundId); // play the sound
        } else if (collection === "phonemes" && this.sounds[`${collection}/${sound}/official`]) {
          // replace the phoneme sound to official's if author's is missing
          instance = Sound.play(`${collection}/${sound}/official`);
        } else {
          this.waitToPlay.add(soundId);
        }
        // handle callback function
        if (typeof callback === "function") {
          if (instance) {
            instance.on("complete", callback, this);
          } else {
            callback();
          }
        }
      }, delay * 1000);
    },
    register({
      sounds, id, src, uri,
    }) {
      if (!this.initialized) { return; }
      try {
        if (sounds) { Sound.registerSounds(sounds); }
        if (src && id) { Sound.registerSound(src, id, { storageURI: uri }); }
      } catch (error) {
        if (id) { console.log(`Unable to register ${id}: ${src}`); }
      }
    },
  },
  created() {
    // if initializeDefaultPlugins returns false, we cannot play sound in this browser
    if (!Sound.initializeDefaultPlugins()) { return; }
    this.initialized = true;
    // initialize default sound effects
    ["choose", "select", "click", "message", "warning", "error"].forEach((id) => {
      this.register({ id: `soundEffects/${id}`, src: soundsUrl.get(id) });
    });
    Sound.alternateExtensions = ["mp3"];
    const { sounds, waitToPlay } = this;
    Sound.addEventListener("fileload", (event) => {
      sounds[event.id] = Sound.createInstance(event.id);
      if (waitToPlay.has(event.id)) {
        Sound.play(event.id); waitToPlay.delete(event.id);
      }
    });
    Sound.addEventListener("error", async (error) => { // Handle Error Events
      console.log("Failed to register audio. error:", error);
    });
  },
});
