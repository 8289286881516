import Vue from "vue";
import { getDoc, onSnapshot } from "firebase/firestore";
import { decoratePacket, updatePacket } from "@/apis/firestore/packet";
import { preloadImage } from "@/scripts/preload";

let lastUpdate = { id: "", time: undefined };

const template = () => ({
  text: "",
  description: "",
  tags: [],
  quizzes: [],
  public: false,
});

const initialState = () => ({
  unsubscribes: new Map(),
});

const packets = {
  state: initialState,
  actions: {
    async getPacket({ state, dispatch }, { reference, preload = false } = {}) {
      if (!reference || reference.id in state) { return; }
      const snap = await getDoc(reference);
      if (!snap.exists()) { return; }
      // mount Packet
      dispatch("mountPacket", {
        data: snap.data(), reference, preload,
      });
    },
    syncPacket({ state, dispatch, commit }, { reference, preload = false } = {}) {
      if (!reference || state.unsubscribes.has(reference.id)) { return; }
      const unsubscribe = onSnapshot(reference, (snap) => {
        if (!snap.exists()) { return; }
        // ignore change while Packet just updated
        const now = Date.now();
        if (lastUpdate.id === reference.id && (now - lastUpdate.time < 5000)) { return; }
        // mount Packet
        dispatch("mountPacket", {
          data: snap.data(), reference, preload,
        });
      });
      commit("addUnsubscribe_Packet", { key: reference.id, unsubscribe });
    },
    importPackets({ state, dispatch }, { docs, preload } = {}) {
      if (!docs || docs.length === 0) { return; }
      docs.forEach((packet) => {
        if (packet.id in state || !packet.exists()) { return; }
        // mount Packet
        dispatch("mountPacket", {
          data: packet.data(), reference: packet.ref, preload,
        });
      });
    },
    mountPacket({ commit, dispatch }, {
      data, reference, preload,
    }) {
      decoratePacket(data, (packet) => {
        if (preload && packet.imgURL) {
          preloadImage(packet.imgURL, reference.path);
        }
        commit("addPacket", {
          id: reference.id,
          packet: {
            ...template(),
            ...packet,
            ref: reference,
          },
        });
      });
      if (preload && data.owner) {
        dispatch("getPlayer", { id: data.owner });
      }
    },
    updatePacket({ commit }, { updateData, reference, lazy = true }) {
      commit("setPacket", { updateData, reference, lazy });
      return updatePacket(updateData, reference);
    },
  },
  mutations: {
    addPacket(state, payload) {
      if (state[payload.id]) {
        state[payload.id] = payload.packet;
      } else {
        Vue.set(state, payload.id, payload.packet);
      }
    },
    addUnsubscribe_Packet(state, { key, unsubscribe }) {
      state.unsubscribes.set(key, unsubscribe);
    },
    resetPackets(state) {
      // Release firestore subscribe
      state.unsubscribes.forEach((unsubscribe) => unsubscribe());
      // Reset
      const newState = initialState();
      Object.keys(state).forEach((key) => {
        if (Object.keys(newState).includes(key)) {
          state[key] = newState[key];
        } else {
          delete state[key];
        }
      });
    },
    setPacket(state, { updateData, reference, lazy = true }) {
      if (!reference || !reference.id || !(state[reference.id])) { return; }
      state[reference.id] = { ...state[reference.id], ...updateData };
      if (lazy) { lastUpdate = { id: reference.id, time: Date.now() }; }
    },
  },
};

export default packets;
