import Vue from "vue";
import * as vClickOutside from "v-click-outside-x";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebase from "@/plugins/firebase";
import Audio from "./audio";
import App from "./App.vue";
import EventBus from "./eventBus";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/main.css";

Vue.use(vClickOutside);
Vue.use(FloatingVue);

Vue.config.productionTip = false;
Vue.prototype.$audio = Audio;
Vue.prototype.$bus = EventBus;

let app;

const auth = getAuth(firebase);
// Listen to auth state changes.
onAuthStateChanged(auth, async (user) => {
  await store.dispatch("fetchUser", user); // wait user data loaded in
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
