<script>
import screenfull from "screenfull";

export default {
  name: "ScreenfullButton",
  data: () => ({
    isFullscreen: false,
    isEnabled: false,
  }),
  methods: {
    toggleFullscreen() {
      if (!this.isEnabled) { return; }
      if (this.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request();
      }
    },
    updateState() {
      this.isFullscreen = screenfull.isFullscreen;
    },
  },
  created() {
    this.isEnabled = screenfull.isEnabled;
    this.updateState();
    if (screenfull.isEnabled) {
      screenfull.on("change", this.updateState);
    }
  },
  beforeDestroy() {
    if (this.isEnabled) {
      screenfull.off("change", this.updateState);
    }
  },
};
</script>
<template> <!-- eslint-disable -->
  <button 
    type="button"
    id="screenfull-btn" 
    v-show="isEnabled"
    @click="toggleFullscreen"
  >
    <svg 
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor" 
    viewBox="0 0 16 16"
    >
      <path 
        v-if="!isFullscreen"
        id="fullscreen" 
        d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" 
      />
      <path
        v-else
        id="fullscreen-exit"
        d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"
      />
    </svg>
  </button>
</template>
<style scoped lang="css">
#screenfull-btn { @apply
  text-gray-500 opacity-60 hover:opacity-100;
}
</style>
