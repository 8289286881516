import Vue from "vue";
import { getPlayer } from "@/apis/firestore/player";

const initialState = () => ({
  onLoading: new Set(),
});

const players = {
  state: initialState,
  actions: {
    async getPlayer({ state, commit, rootState }, { id } = {}) {
      if (!id || id in state || state.onLoading.has(id)) { return; }
      commit("onloadPlayer", { id });
      if (id === rootState.user.ref.id) {
        commit("addPlayer", { id, data: rootState.user }); return;
      }
      const snap = await getPlayer(id);
      if (!snap || !snap.exists()) { return; }
      commit("addPlayer", { id, data: { ...snap.data(), ref: snap.ref } });
    },
  },
  mutations: {
    onloadPlayer(state, { id }) {
      state.onLoading.add(id);
    },
    addPlayer(state, { id, data }) {
      state.onLoading.delete(id);
      if (state[id]) {
        state[id] = data;
      } else {
        Vue.set(state, id, data);
      }
    },
  },
};

export default players;
