<template>
  <div class="home">
    <scoreTag :height="5" :isExchangable="true" />
    <div id="main">
      <div v-show="hint" id="hint">
        <span v-if="hint == 1">
          已經沒有待複習或學習單字了~<br>
          到 <router-link to="/bank">單字庫</router-link> 中添加新單字吧！
        </span>
        <span v-else-if="hint == 2">
          還有 <strong class="text-green-600">{{amountToReview}}</strong> 個要複習的單字喔！ 加油！<br>
        </span>
      </div>
      <button type="button" id="play" :class="{ enable: playable }" @click="play">
        <loading v-if="onloading" class="load-icon" />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="play-icon"
          viewBox="0 0 16 16"
        >
          <path
            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c
            0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
        </svg>
      </button>
      <div id="config">
        <button
          type="button"
          class="config-button"
          id="only-touchable"
          :class="{ enable: onlyTouchable }"
          @click="changeOnlyTouchable"
        >
          <indexFinger v-show="onlyTouchable" class="index-finger icon" />
          <keyboard v-show="!onlyTouchable" class="keyboard icon" />
          <span>{{onlyTouchable ? "僅用觸控螢幕" : "使用實體鍵盤"}}</span>
        </button>
        <button type="button" @click="onConfigSetting = true">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="gear-fill icon" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
          </svg>
        </button>
      </div>
    </div>
    <PopUp v-if="onConfigSetting" @exit="onConfigSetting = false">
      <ConfigPanel />
    </PopUp>
    <ScreenfullButton class="screenfull-btn" />
  </div>
</template>

<style scoped lang="css">
  .home {
    @apply h-screen w-screen flex justify-center items-center;
  }
  #main {
    @apply relative flex flex-col items-center top-16;
  }
  #hint {
    @apply absolute w-max -top-24 px-4 py-2 rounded-3xl bg-white text-gray-700 ;
  }
  #hint a { @apply text-green-500; }
  button#play {
    @apply rounded-full bg-gray-300 cursor-not-allowed
    border-white border-8 shadow-2xl
    flex justify-center items-center;
    height: calc(100vw * 3 / 5);
    width: calc(100vw * 3 / 5);
  }
  button#play.enable {
    @apply bg-gradient-to-bl from-green-500 to-green-600 cursor-pointer;
  }
  button#play>svg.play-icon {
    @apply h-4/5 w-4/5 text-white;
    padding-left: 10%;
  }
  button#play>.load-icon {
    @apply h-4/5 w-4/5 text-white;
    animation: spin 3s linear infinite;
  }
  #config {
    @apply flex mt-6 divide-x divide-white;
  }
  #config>button:first-child { @apply rounded-l-full; }
  #config>button:last-child { @apply rounded-r-full; }
  #config>button {
    @apply px-10 py-4 mx-0 cursor-pointer
    bg-gray-400 text-lg text-white hover:bg-gray-500
    flex justify-center items-center
    whitespace-nowrap;
  }
  #config>button>.icon {
    @apply inline-block h-6 w-6 mr-2 text-white;
    position: relative;
  }
  #config>button.enable {
    @apply bg-green-500 hover:bg-green-600;
  }

  .screenfull-btn { @apply
    w-5 h-5 absolute right-4 bottom-4;
  }

  /* on tablet */
  @media (min-width: 768px) {
    #hint {
      @apply text-xl;
    }
    button#play {
      height: calc(100vh * 2 / 5);
      width: calc(100vh * 2 / 5);
    }
    #config>button {
      @apply px-4 py-4 mx-0 text-2xl;
    }
    #config>button>.icon {
      @apply h-10 w-10;
    }
    #config>button>.icon+span { @apply
      ml-3
    }
  }

  /* on pc */
  @media (min-width: 1024px) {
    #main { @apply top-0; }
  }

</style>

<script>
import { DEFAULT_BACKGROUND_URL } from "@/config";
import { action } from "@/store/actions";
import scoreTag from "@/components/ScoreTag.vue";
import ScreenfullButton from "@/components/Widgets/ScreenfullButton.vue";
import auth from "@/mixins/auth";
import PopUp from "@/components/EditorV2/Pop-up/index.vue";
import keyboard from "./keyboard-icon.vue";
import indexFinger from "./index-finger-icon.vue";
import loading from "./loading-icon.vue";
import ConfigPanel from "./ConfigPanel.vue";

export default {
  name: "HomePage",
  components: {
    keyboard, indexFinger, scoreTag, loading, ScreenfullButton, PopUp, ConfigPanel,
  },
  mixins: [auth],
  data: () => ({
    onConfigSetting: false,
  }),
  computed: {
    onloading() {
      return this.$store.state.stream.onWaiting;
    },
    onlyTouchable() {
      const { draggable, typable } = this.$store.getters.user.config;
      return draggable && !typable;
    },
    playable() {
      return !!(this.$store.state.stream.word);
    },
    needMoreQuizzes() {
      const { onWaiting, word } = this.$store.state.stream;
      if (onWaiting) { return false; }
      return word === null;
    },
    hint() {
      if (this.onloading) { return 0; }
      if (!this.playable && this.needMoreQuizzes) { return 1; }
      if (this.amountToReview) { return 2; }
      return 0;
    },
    amountToReview() {
      return this.$store.state.stream.amountToReview;
    },
  },
  methods: {
    play() {
      if (!this.playable) { return; }
      this.$audio.play({ id: "soundEffects/choose" });
      this.$router.push({ name: "FlowGame" });
    },
    changeOnlyTouchable() {
      if (this.onloading) { return; }
      const { typable } = this.$store.getters.user.config;
      const config = { draggable: true, typable: !typable };
      this.$store.dispatch("updataUserConfig", config);
      this.$store.dispatch("stream/getQuiz", { audioRegister: this.$audio.register });
    },
  },
  created() {
    document.getElementById("backdrop").style.backgroundImage = `url('${DEFAULT_BACKGROUND_URL}')`;
    this.checkEmailVerified();
    // preload phonemes
    if (this.$store.state.phonemes === null) {
      this.$store.dispatch(action.getPhonemes, "English");
    }
    // preload official phonetic Sounds if not ready
    if (!this.$store.state.phoneticSounds.official) {
      this.$store.dispatch("getPhoneticSounds", { lang: "English", author: "official" });
    }
    // subscribe to preload quizzes
    if (!this.$store.state.stream.onListeningPreload) {
      this.$store.dispatch("stream/connectPreloadQuizzes", { audioRegister: this.$audio.register });
    }
  },
  mounted() {
    // get Quiz
    if (this.$store.state.stream.word === undefined) {
      this.$store.dispatch("stream/getQuiz", { audioRegister: this.$audio.register });
    }
    if (!this.$store.getters.user.config.EnglishLevel) {
      this.onConfigSetting = true;
    }
  },
};
</script>
