export default new Map([
  ["incorrect", require("./incorrect.wav")],
  ["sparkle", require("./sparkle.mp3")],
  ["choose", require("./choose.wav")],
  ["click", require("./click.wav")],
  ["counting", require("./counting.mp3")],
  ["error", require("./error.wav")],
  ["getting-star", require("./getting-star.wav")],
  ["message", require("./message.wav")],
  ["mission-complete", require("./mission-complete.wav")],
  ["select", require("./select.wav")],
  ["spend", require("./spend.mp3")],
  ["warning", require("./warning.wav")],
]);
