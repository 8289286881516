<template src="./template.html" />
<style scoped src="./style.css"></style>

<script>
import { action } from "@/store/actions";

export default {
  name: "EventNotification",
  components: {
    Bill: () => import(/* webpackChunkName: "Score" */"@/components/BillDialog.vue"),
  },
  computed: {
    notification() { return this.$store.state.notification; },
  },
  watch: {
    notification() {
      if (this.notification) {
        switch (this.notification.type) {
          case "error":
            this.$audio.play({ id: "soundEffects/error" });
            break;
          case "warning":
            this.$audio.play({ id: "soundEffects/warning" });
            break;
          default:
            this.$audio.play({ id: "soundEffects/message" });
        }
        if (this.notification.buttons && this.notification.buttons.length === 1) {
          window.addEventListener("keydown", this.KeyEventHandler);
        }
      } else {
        window.removeEventListener("keydown", this.KeyEventHandler);
      }
    },
  },
  methods: {
    clickBTN(callback) {
      this.$audio.play({ id: "soundEffects/click" });
      callback();
    },
    blur() {
      if (this.notification && this.notification.blur) {
        this.notification.blur();
      } else {
        this.$store.dispatch(action.setNotification, null);
      }
    },
    KeyEventHandler(e) {
      e.stopPropagation();
      switch (e.key) {
        case "Enter":
          this.notification.buttons[0].callback();
          break;
        case "Escape":
          this.notification.buttons[0].callback();
          break;
        default:
          break;
      }
    },
  },
};

</script>
