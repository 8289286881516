import Vue from "vue";
import { initialState } from "./data";

// mutation-types
export const mutation = {
  setUser: "setUser",
  setUserConfig: "setUserConfig",
  setUserTags: "setUserTags",
  setIdToken: "setIdToken",
  login: "login",
  clearUser: "clearUser",
  reset: "reset",
  setGame: "setGame",
  removeGame: "removeGame",
  setQuestion: "setQuestion",
  setPhonemes: "setPhonemes",
  setPhoneticSounds: "setPhoneticSounds",
  setDefaultPhonemes: "setDefaultPhonemes",
  setSoundEffects: "setSoundEffects",
  setPlayerData: "setPlayerData",
  addMissions: "addMissions",
  addQuestion: "addQuestion",
  setNotification: "setNotification",
  addUnsubscribe: "addUnsubscribe",
  updateConfig: "updateConfig",
};

const mutations = {
  setUser(state, user) { state.user = { ...user.data(), ref: user.ref }; },
  setUserConfig(state, userConfig) { Vue.set(state.user, "config", userConfig); },
  setUserScore(state, newScore) { Vue.set(state.user, "score", newScore); },
  setUserTags(state, newTags) { Vue.set(state.user, "tags", newTags); },
  setUserExchangeHistory(state, history) { Vue.set(state.user, "exchangeHistory", history); },
  setIdToken(state, idToken) { state.idToken = idToken; },
  login(state) { state.isLoggedIn = true; },
  clearUser(state) { state.user = null; state.idToken = undefined; state.isLoggedIn = false; },
  reset(state) {
    const newState = initialState();
    Object.keys(state).forEach((key) => {
      if (["games", "stages", "missions", "questions", "flow", "cookies"].includes(key)) { return; }
      if (Object.keys(newState).includes(key)) {
        state[key] = newState[key]; return;
      }
      delete state[key];
    });
  },
  setGame(state, game) { Vue.set(state, "game", game); },
  setQuestion(state, question) { state.question = question; },
  setPhonemes(state, phonemes) { state.phonemes = phonemes; },
  setDefaultPhonemes(state, defaultPhonemes) { Vue.set(state, "defaultPhonemes", defaultPhonemes); },
  setPhoneticSounds(state, sound) { Vue.set(state, "phoneticSounds", sound); },
  setSoundEffects(state, soundEffects) { state.soundEffects = soundEffects; },
  setPlayerData(state, playerData) { state.playerData = playerData; },
  addQuestion(state, payload) {
    Vue.set(state.questions, payload.path, payload.question);
  },
  setNotification(state, payload) {
    if (payload) { state.notification = { ...payload }; } else { state.notification = null; }
  },
  addUnsubscribe(state, unsubscribe) { state.unsubscribes.push(unsubscribe); },
  updateConfig(state, config) { Vue.set(state, "config", { ...state.config, ...config }); },
  updatePosition(state, payload) {
    state.position[payload.page] = { x: payload.x || 0, y: payload.y || 0 };
  },
};

export default mutations;
