<script>
export default {
  name: "ConfigPanel",
  data: () => ({
    script: {
      title: "設定",
      caption: { EnglishLevel: "英文程度" },
      label: {
        Level1: "初級",
        Level2: "中級",
        Level3: "中高級",
      },
      note: {
        Level3: "測試版",
      },
      instruction: {
        Level1: "剛開始學習自然發音 (Phonics) ，仍未熟悉字母的基本發音。<br>初期提供較多的協助，目標著重於建立字母與發音的直覺音感。<br>(建議使用觸控螢幕)",
        Level2: "熟悉基本的字母發音 (A-Z)，開始接觸複合字母(ee、sh...)。<br>練習目標著重於熟悉英文單字的拼寫與基本認識。<br>(建議使用實體鍵盤)",
        Level3: "有自然發音 (Phonics) 基礎，並且能閱讀簡單的英文句子。<br>練習增加克漏字題型，透過大量的文句，熟悉單字在句子中的運用。",
      },
    },
    changes: {},
  }),
  computed: {
    EnglishLevel: {
      get() {
        if (this.changes.EnglishLevel) { return this.changes.EnglishLevel; }
        const { EnglishLevel } = this.$store.getters.user.config;
        if (EnglishLevel) { return EnglishLevel; }
        return undefined;
      },
      set(level) {
        this.changes.EnglishLevel = +level;
        this.changes.isBeginner = +level <= 1;
      },
    },
  },
  beforeDestroy() {
    if (Object.keys(this.changes).length > 0) {
      // Update user configurations
      this.$store.dispatch("updataUserConfig", this.changes);
      this.$store.dispatch("stream/getQuiz", { audioRegister: this.$audio.register });
    }
  },
};
</script>
<template> <!-- eslint-disable -->
  <div id="config-panel">
    <header>
      <h2>{{ script.title }}</h2>
    </header>
    <div class="line"/>
    <form action="#" @submit.stop.prevent="">
      <fieldset id="English-level">
        <legend class="caption">{{ script.caption.EnglishLevel }}</legend>
        <div class="radio-option">
          <input type="radio" id="level1" name="EnglishLevel" value="1" v-model="EnglishLevel">
          <label for="level1">
            <div>
              <h5>{{ script.label.Level1 }}</h5>
              <span class="note">{{ script.note.Level1 }}</span>
            </div>
            <p v-html="script.instruction.Level1"/>
          </label>
        </div>
        <div class="radio-option">
          <input type="radio" id="level2" name="EnglishLevel" value="2" v-model="EnglishLevel">
          <label for="level2">
            <div>
              <h5>{{ script.label.Level2 }}</h5>
              <span class="note">{{ script.note.Level2 }}</span>
            </div>
            <p v-html="script.instruction.Level2"/>
          </label>
        </div>
        <div class="radio-option">
          <input type="radio" id="level3" name="EnglishLevel" value="3" v-model="EnglishLevel">
          <label for="level3">
            <div>
              <h5>{{ script.label.Level3 }}</h5>
              <span class="note">{{ script.note.Level3 }}</span>
            </div>
            <p v-html="script.instruction.Level3"/>
          </label>
        </div>
      </fieldset>
    </form>
  </div>
</template>
<style scoped lang="css">
#config-panel { @apply
  bg-white px-5 py-4 shadow-sm rounded-md max-w-xl;
}
header { @apply
  flex items-center;
}
header>h2 { @apply
  text-2xl ml-1 font-black text-gray-700;
}
.line { @apply
  w-full border-t my-2;
}
form .caption { @apply
  text-xl font-bold;
}

.radio-option { @apply
  flex items-start my-2 ml-2;
}
.radio-option>input { @apply
  my-2 mr-2;
}
.radio-option>label { @apply
  cursor-pointer;
}
.radio-option h5 { @apply
  inline text-lg font-semibold;
}
.radio-option .note { @apply
  ml-2 text-sm text-yellow-600;
}
.radio-option p { @apply
  text-base text-gray-600;
}
</style>
