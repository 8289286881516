import moduleCreatejs from "createjs-libs/build/preloadjs";
import { getFileURL } from "@/apis/storage";

const createjs = window.createjs ? window.createjs : moduleCreatejs;

const preloadQueue = new createjs.LoadQueue();

export const loadedImages = new Map();

function handleFileload(evt) {
  const { id, src, type } = evt.item;
  if (type === "image") {
    const blob = preloadQueue.getResult(id, true);
    if (!blob) { return; }
    loadedImages.set(id, { src, loaded: window.URL.createObjectURL(blob) });
  }
}

preloadQueue.on("fileload", handleFileload, this);

preloadQueue.on("error", async (error) => {
  if (error.data && error.data.storageURI) { // If parameter has Storage URI
    const newUrl = await getFileURL(error.data.storageURI);
    preloadQueue.loadFile({
      id: error.data.id, src: newUrl.data, type: error.data.type,
    });
  }
});

export function preloadImage(url, id, { force = false, uri } = {}) {
  const image = loadedImages.get(id);
  if (image && image.src === url) { // has already been loaded
    if (force) {
      preloadQueue.remove(id);
      loadedImages.set(id, { src: url });
      preloadQueue.loadFile({ id, src: url, type: createjs.Types.IMAGE });
    }
    return;
  }
  const exist = preloadQueue.getResult(url, true);
  if (!image && exist) {
    loadedImages.set(id, { src: url, loaded: window.URL.createObjectURL(exist) });
    return;
  }
  loadedImages.set(id, { src: url });
  preloadQueue.loadFile({
    id, src: url, type: createjs.Types.IMAGE, storageURI: uri,
  });
}

export function getImage(id) {
  const image = loadedImages.get(id);
  if (!image) { return null; }
  return image.loaded || image.src;
}

export function preloadDefaultImages(images) {
  Object.entries(images).forEach(([id, url]) => { preloadImage(url, id); });
}

const hasLoaded = [];

export function preload(url, type, position = "body") {
  const id = url.split("/")[url.split("/").length - 1].split(".")[0];
  if (hasLoaded.includes(id)) { return; }
  const el = document.createElement("link");
  el.as = type;
  el.rel = "preload";
  el.href = url;
  if (position === "body") { document.body.appendChild(el); }
  if (position === "head") { document.head.appendChild(el); }
  hasLoaded.push(id);
}

export function prefetch(url, type) {
  const el = document.createElement("link");
  el.as = type;
  el.rel = "prefetch";
  el.href = url;
  document.body.appendChild(el);
}
