import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { initialState } from "./data";
import games from "./module/games";
import stages from "./module/stages";
import missions from "./module/missions";
import questions from "./module/questions";
import cookies from "./module/cookies";
import flow from "./module/flow";
import reports from "./module/reports";
import packets from "./module/packets";
import players from "./module/players";
import stream from "./module/stream";

Vue.use(Vuex);

export default new Vuex.Store({
  state: initialState(),
  mutations,
  actions,
  getters,
  modules: {
    games,
    stages,
    questions,
    missions,
    cookies,
    flow,
    reports,
    packets,
    players,
    stream,
  },
});
