<template src="./template.html" />
<style scoped src="./style.css"></style>

<script>
export default {
  name: "VectorIcon",
  props: {
    id: { type: String, required: true },
    size: { type: String, required: true },
    type: { type: String, default: "outline" },
    animate: { type: String, default: "none" },
  },
  computed: {
    style() { return `${this.size} animate-${this.animate}`; },
  },
};

</script>
