import api from "./cloudFunctions";

async function getClozeSuggestions(idToken, quizId, params = {}) {
  api.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  try {
    await api.patch(`/quiz/${quizId}/suggestions`, {
      params: { limit: 5, ...params },
    });
  } catch (err) {
    if (err.code === "ECONNABORTED" && err.message === "timeout of 10000ms exceeded") {
      return;
    }
    if (err.response && err.response.status === 401) {
      throw err;
    }
    console.log("Unknow Error", err);
  }
}

async function getClozeQuizzes(idToken, quizId, params = {}, idTokenGenerator = null) {
  api.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  try {
    await api.get(`/quiz/${quizId}/ai-clozes`, {
      params: { ...params },
    });
  } catch (err) {
    if (err.code === "ECONNABORTED" && err.message === "timeout of 10000ms exceeded") {
      return;
    }
    if (err.response && err.response.status === 401 && idTokenGenerator) { // Unauthorized
      const newIdToken = await idTokenGenerator();
      await getClozeQuizzes(newIdToken, quizId, params);
    }
    console.log("Unknow Error", err);
  }
}

function modifyClozeQuiz(idToken, quizId, clozeId, updateData, params = {}) {
  api.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  return api.patch(
    `/quiz/${quizId}/cloze/${clozeId}`,
    { update: updateData },
    { params: { ...params } },
  );
}

function reTTSClozeQuiz(idToken, quizId, clozeId, params = {}) {
  api.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  return api.patch(
    `/quiz/${quizId}/cloze/${clozeId}`,
    {},
    { params: { retts: true, ...params } },
  );
}

export default {
  getClozeSuggestions, getClozeQuizzes, modifyClozeQuiz, reTTSClozeQuiz,
};
