<template>
  <div id="avatar-selector" class="cover" @click="$emit('exit')">
    <div id="selector">
      <div id="frame">
        <div id="left switcher" />
        <div id="showcase">
          <div
            v-for="(avatar) in avatars"
            :key="avatar.id"
            class="avatar"
            @click.stop="select(avatar.url)">
            <img :src="avatar.url" :alt="avatar.id">
            <div v-show="currentImage === avatar.url" class="selected">
              <Icon id="check" size="xl" />
            </div>
          </div>
        </div>
        <div id="right switcher" />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon/index.vue";

export default {
  name: "avatarSelector",
  components: { Icon },
  props: {
    avatars: { type: Array, required: true },
    currentImage: { type: String, required: true },
  },
  data: () => ({
  }),
  methods: {
    select(url) {
      this.$audio.sounds["soundEffects/select"].play();
      if (url === this.currentImage) { this.$emit("exit"); return; }
      this.$emit("select", url);
    },
  },
};
</script>

<style scoped lang="css">
.cover {
  @apply fixed inset-0 h-full w-full z-30
  flex justify-center items-center;
}
#selector { @apply
  px-5 py-3
  bg-tan-50 rounded-2xl shadow-lg;
  width: 840px;
}
#frame { @apply flex; }
#showcase { @apply
  flex-grow
  flex flex-wrap justify-evenly;
}
.avatar { @apply relative m-3; }
.avatar>img { @apply
  w-40 h-40 object-cover rounded-full cursor-pointer
  border-4 border-white bg-white shadow-md
}
.avatar>.selected { @apply
  absolute inset-0 z-10
  rounded-full bg-black bg-opacity-60 text-white
  flex justify-center items-center;
}
.btn { @apply cursor-pointer; }
.btn.upload { @apply
  w-40 h-40 object-cover rounded-full cursor-pointer
  border-4 border-white bg-white shadow-md
  flex justify-center items-center text-gray-400;
}
/* on mobile */
#selector { @apply mx-6 mt-16;}
.avatar>img { @apply w-28 h-28; }
/* on tablet */
@media (min-width: 768px) {
  .avatar>img { @apply w-36 h-36; }
}
/* on pc */
 @media (min-width: 1024px) {
   #selector { @apply ml-16 mt-0 mr-0;}
   .avatar>img { @apply w-40 h-40; }
 }
</style>
